import React from 'react';
import { Stack, Typography } from '@mui/material';

const SlotsNet = ({ days, slots, tiles, handleTileChange }) => {

  const generateNet = () => {
    const net = [];
    let counter = 0;
    for (let i = 0; i < days; i++) {
      const day = [];
      for (let j = 0; j < slots; j++) {
        day.push(counter);
        counter++;
      }
      net.push(day);
    }
    return net;
  };

  const hours = Array.from({ length: slots }, (_, i) => `${i + 1} godz. lekcyjna`);
  const daysHeaders = Array.from({ length: days }, (_, i) => `Dzień ${i + 1}`);

  return (
    <Stack mt={2} mb={2}>
      <Typography variant="h6">Wybierz preferowane godziny oraz dni lekcyjne:</Typography>
      <div style={{ display: 'flex', marginBottom: '10px', justifyContent:'center' }}>
        <div style={{ width: '90px' }}></div>
        {daysHeaders.map((day, index) => (
          <div
            key={`day-header-${index}`}
            style={{
              width: '60px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {day}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex' , justifyContent: 'center'}}>
        <div>
          {hours.map((hour, index) => (
            <div
              key={`hour-${index}`}
              style={{
                width: '90px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {hour}
            </div>
          ))}
        </div>
        {generateNet().map((column, columnIndex) => (
          <div key={`column-${columnIndex}`} >
            {column.map((slot, slotIndex) => (
              <div
                key={`slot-${slotIndex}`}
                style={{
                  width: '60px',
                  height: '60px',
                  border: '1px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  backgroundColor: tiles.includes(slot) ? 'green' : 'grey',
                }}
                onClick={() => handleTileChange(slot)}
              >
                {/* {slot + 1} */}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Stack>
  );
};

export default SlotsNet;