import React, { useState } from "react";
import styles from "./Navigation.module.scss";
import Logo from "../../icons/es-logo.png";
import { mainNavItems } from "./nav.config";
import { Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MobileNav from "./MobileNav";

const MainNav = ({ loading, isUserLogged, schoolName, navigate }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setIsOpenDrawer(newOpen);
  };

  return (
    <div className={styles.mainNav}>
      <div className={styles.logo}>
        <img src={Logo} alt="logo Easy schedule" />
      </div>
      <div className={styles.nav}>
        <span className={styles.menuIcon} onClick={toggleDrawer(!isOpenDrawer)}>
          <MenuIcon />
        </span>
        {mainNavItems.map((mni, index) => (
          <div className={styles.menuItem}>
            {mni.render}
            {mainNavItems.length - 1 !== index && (
              <Divider orientation="vertical" flexItem />
            )}
          </div>
        ))}
        <MobileNav
          isOpenDrawer={isOpenDrawer}
          handleDrawer={toggleDrawer}
          loading={loading}
          isUserLogged={isUserLogged}
          schoolName={schoolName}
          navigate={navigate}
        />
      </div>
    </div>
  );
};

export default MainNav;
