import React, { useEffect, useState } from "react";
import styles from "./Arrow.module.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const Arrow = () => {
  const [arrowVisible, setArrowVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setArrowVisible(true);
      } else {
        setArrowVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={
        arrowVisible ? `${styles.arrow} ${styles.visible}` : styles.arrow
      }
    >
      <a href="#top">
        <KeyboardArrowUpIcon />
      </a>
    </div>
  );
};

export default Arrow;
