import { Container, Typography, Box } from "@mui/material";

const SLA = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Service Level Agreement (SLA)
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Definicje
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Usługa: Aplikacja SaaS do generowania planów lekcji.
          <br />
          ● Użytkownik: Osoba fizyczna lub prawna korzystająca z Usługi.
          <br />● Operator: Właściciel aplikacji SaaS.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Cel SLA
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● SLA określa poziom usług świadczonych przez Operatora oraz obowiązki
          Operatora i Użytkownika.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Zakres Usług
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Dostępność aplikacji: 99,5% czasu w miesiącu.
          <br />
          ● Czas reakcji na zgłoszenia: do 24 godzin w dni robocze.
          <br />● Czas rozwiązania problemu: do 72 godzin w dni robocze.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Obsługa Techniczna
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Operator zapewnia wsparcie techniczne poprzez email:
          kontakt@easyschedule.pl
          <br />● Godziny wsparcia: od poniedziałku do piątku, 9:00 - 17:00.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Zgłaszanie Awarii
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Użytkownik zgłasza awarie poprzez email lub formularz na stronie.
          <br />● Operator zobowiązuje się do potwierdzenia przyjęcia zgłoszenia
          w ciągu 24 godzin.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Utrzymanie i Aktualizacje
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Planowane prace serwisowe będą komunikowane z 48-godzinnym
          wyprzedzeniem.
          <br />● Operator zastrzega sobie prawo do nieplanowanych prac
          serwisowych w nagłych przypadkach.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Poziomy Usług
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Podstawowy: 99,5% dostępności, czas reakcji 24 godziny, czas
          rozwiązania problemu 72 godziny.
          <br />● Premium: 99,9% dostępności, czas reakcji 12 godzin, czas
          rozwiązania problemu 48 godzin.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Wyłączenia
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Operator nie odpowiada za problemy wynikłe z działania siły wyższej,
          działań Użytkownika lub awarii zewnętrznych usługodawców.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Kary Umowne
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● W przypadku nieosiągnięcia poziomu usług Operator zobowiązuje się do
          zwrotu 10% miesięcznej opłaty licencyjnej za każdy procent poniżej
          gwarantowanej dostępności.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Postanowienia Końcowe
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Niniejsze SLA stanowi integralną część umowy licencyjnej.
          <br />● Wszelkie zmiany SLA wymagają formy pisemnej.
        </Typography>
      </Box>
    </Container>
  );
};

export default SLA;
