export const mainNavItems = [
  {
    id: 1,
    name: "Opis",
    href: "#description",
    render:  <a href="#description">Opis</a>
  },
  {
    id: 2,
    name: "Funkcjonalności",
    href: "#functionalities",
    render: <a href="#functionalities">Funkcjonalności</a>
  },
  {
    id: 3,
    name: "Możliwości",
    href: "#capabilities",
    render: <a href="#capabilities">Możliwości</a>
  },
  {
    id: 4,
    name: "Korzyści",
    href: "#benefits",
    render: <a href="#benefits">Korzyści</a>
  },
  {
    id: 5,
    name: "Metody Płatności",
    href: "#payment",
    render: <a href="#payment">Metody Płatności</a>
  },
]