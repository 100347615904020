import React from "react";
import { Container } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import styles from "./Description.module.scss";
import { steps } from "./descriptionData";

const Description = () => {
  return (
    <Container>
      <div className={styles.description} id="description">
        <div className={styles.text}>
          <h2>Opis produktu</h2>
          <p>
            Nasza aplikacja to kompleksowe rozwiązanie, które umożliwia łatwe i
            szybkie tworzenie harmonogramów lekcji, idealnie dostosowanych do
            indywidualnych potrzeb każdej szkoły. Wystarczy wprowadzić
            podstawowe dane o szkole, określić specyficzne wymagania dotyczące
            przedmiotów, nauczycieli oraz dostępności sal, a aplikacja
            wygeneruje optymalny harmonogram w zaledwie kilka minut. Dzięki
            naszej aplikacji zaoszczędzisz czas i zminimalizujesz błędy,
            zapewniając jednocześnie harmonogram, który spełnia wszystkie
            potrzeby Twojej placówki.
          </p>

          <div className={styles.steps}>
            <div className={styles.stepsWrapper}>
              {steps.map((step, index) => (
                <Step key={index} step={step} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Step = ({ step }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className={styles.step}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <h4>{step.step}</h4>
      <div className={styles.stepDescription}>
        <p>{step.description}</p>
      </div>
    </motion.div>
  );
};

export default Description;
