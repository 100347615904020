import React from "react";
import { Select, MenuItem } from "@mui/material";

const SubjectRow = ({ handleSubjectChange, subject, subjects }) => {
  return (
    <Select
      labelId="subject"
      id="subject"
      value={subject?._id?.$oid || ""}
      label="Przedmiot"
      onChange={handleSubjectChange}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      }}
    >
      {subjects.data.map((subject) => (
        <MenuItem key={subject._id.$oid} value={subject._id.$oid}>
          {subject.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SubjectRow;
