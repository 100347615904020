import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import Profile from "./components/Profile";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ConfirmedReset from "./components/Auth/ConfirmedReset";

import AuthStore from "./mobx/AuthStore";
import { observer } from "mobx-react";
import PasswordReset from "./components/Auth/PasswordReset";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy/PrivacyPolicy";
import Regulations from "./components/Legal/Regulations/Regulations";
import SLA from "./components/Legal/SLA/SLA";
import License from "./components/Legal/Licence/License";
// import PaymentModule from "./modules/PaymentModule/PaymentModule";
import { Navigate } from "react-router-dom";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import { SiteNotFound } from "./components/SiteNotFound/SiteNotFound";

const Activate = lazy(() => import("./components/Auth/Activate"));

const Root = observer(() => {
  const { isUserLogged, loading } = AuthStore;

  if (loading) return <LoadingBar />;

  return (
    <div>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/profile/*"
          element={
            isUserLogged && !loading ? <Profile /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/login"
          element={
            isUserLogged && !loading ? <Navigate to="/profile" /> : <Login />
          }
        />
        <Route path="/login/:id" element={<Login />} />
        <Route
          path="/register"
          element={
            isUserLogged && !loading ? <Navigate to="/profile" /> : <Register />
          }
        />
        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/reset/confirmed" element={<ConfirmedReset />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/regulations" element={<Regulations />} />
        <Route path="/sla" element={<SLA />} />
        <Route path="/license" element={<License />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="/recovery" element={<ConfirmedReset />} />
        {/* <Route path="/purchase" element={<PaymentModule />} /> */}
        <Route path="/*" element={<SiteNotFound />} />
      </Routes>
    </div>
  );
});

export default Root;
