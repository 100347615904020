import { action, makeAutoObservable } from 'mobx';
import DataService from '../services/DataService';

class PlansStore {
  plans = {
    data: [],
    loading: false,
    error: null,
  };

  constructor() {
    makeAutoObservable(this);
    this.getPlans();
  }

  @action
  setLoading(boolean) {
    this.plans.loading = boolean;
  }

  @action
  setPlans(data) {
    this.plans.data = data;
  }

  getPlanById(id) {
    return this.plans.data.find(plan => plan._id.$oid === id);
  }

  @action
  async getPlans() {
    this.setLoading(true);
    const response = await DataService.getSchoolPlans();
    this.setLoading(false);

    if(response.error) {
      return response.error
    }

    this.setPlans(response);
  }
}

export default new PlansStore();