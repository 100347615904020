import { action, makeAutoObservable, toJS, observable } from "mobx";
import DataService from "../services/DataService";
import SchoolInfoStore from "./SchoolInfoStore";

class AuthStore {
  @observable user = null;
  @observable isUserLogged = false;
  @observable loading = false;

  constructor() {
    makeAutoObservable(this);
    this.isLogged();
  }

  @action
  setIsLogged(bool) {
    this.isUserLogged = bool;
  }

  @action
  setLoading(bool) {
    this.loading = bool;
  }

  @action
  async login(data) {
    localStorage.removeItem("AT");
    const response = await DataService.login("login", data);

    if (response.token) {
      localStorage.setItem("AT", response.token);
      await this.getUser(response.token);
    }

    return response;
  }

  async register(data) {
    const response = await DataService.login("register", data);
    return response;
  }

  async getUser() {
    const user = await DataService.getUserData(`user`);
    SchoolInfoStore.setSchoolPlanId(
      user?.data[0]?.schoolPlanConfigs[0]?._id
    );
    this.setIsLogged(true);

    this.user = {
      id: user.data[0]._id,
      email: user.data[0].email,
      schoolPlanConfigs: user.data[0].schoolPlanConfigs[0],
      plan: user.data[0].plan
    };
  }

  async isLogged() {
    this.setLoading(true);
    const token = localStorage.getItem("AT");

    const isLogged = await DataService.isLogged(token);

    if (token && isLogged) {
      await this.getUser();
      this.setLoading(false);
      return true;
    }

    localStorage.removeItem("AT");
    this.setLoading(false);
    this.setIsLogged(false);
    return false;
  }

  logout() {
    localStorage.removeItem("AT");
    this.user = null;
  }

  async activate(token) {
    this.setLoading(true);
    const response = await DataService.activateUser(token);
    this.setLoading(false);

    return response;
  }

  async resendToken(email) {
    this.setLoading(true);
    await DataService.resendToken(email);
    this.setLoading(false);
  }

  async recoveryPassword(email) {
    this.setLoading(true);
    const response = await DataService.recoveryPassword(email);
    this.setLoading(false);

    return response;
  }

  async resetPassword(password, token) {
    this.setLoading(true);
    const response = await DataService.resetPassword(password, token);
    this.setLoading(false);

    return response;
  }
}

export default new AuthStore();
