import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Button,
  Stack,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./styles.module.scss";
import Logo from "../../icons/LogoES.svg";
import CustomAlert from "../Alert/CustomAlert";
import AuthStore from "../../mobx/AuthStore";
import { authPasswordChecker } from "../../services/authPasswordChecker";

const ConfirmedReset = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const handleLoginRedirect = () => {
    navigate("/login");
  };
  const [password, setPassword] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(false);
  const token = params.get("token");
  const handleResetPassword = async () => {
    const isPasswordCorrect = authPasswordChecker(password, password);

    if (isPasswordCorrect !== true) {
      setError(isPasswordCorrect);

      return;
    }

    const response = await AuthStore.resetPassword(password, token);

    if (response.error) {
      setError(response.error);
    } else {
      setIsSent(true);
      setParams();
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Timetable Planner for schools - Resetowanie hasła</title>
        <meta
          name="description"
          content="Twoje hasło zostało pomyślnie zresetowane. Teraz możesz zalogować się z nowym hasłem."
        />
      </Helmet>
      <Container>
        <div className={styles.auth}>
          <div>
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo Easy schedule"
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
              />
            </NavLink>
          </div>
          <div className={styles.form}>
            <div className={styles.formWrapper}>
              <Stack mb={2}>
                <Typography
                  variant="h3"
                  fontWeight={300}
                  textAlign={"center"}
                  mb={1}
                >
                  Resetowanie Hasła
                </Typography>
                {error && <CustomAlert status="error" message={error} />}
                {isSent ? (
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    fontWeight={300}
                  >
                    <Box component="span" mr={0.5}>
                      Twoje hasło zostało pomyślnie zresetowane. Teraz możesz
                      zalogować się z nowym hasłem.
                    </Box>
                    <NavLink to="/login">Zaloguj się</NavLink>.
                  </Typography>
                ) : (
                  <>
                    <TextField
                      label="Nowe Haslo"
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                    <Button
                      variant="contained"
                      onClick={handleResetPassword}
                      sx={{ mt: 1 }}
                    >
                      Reset
                    </Button>
                  </>
                )}
              </Stack>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default ConfirmedReset;
