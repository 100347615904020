import React from "react";
import styles from "../LessonPlan.module.scss";
import { Stack, Chip } from "@mui/material";

const LessonPlanTile = ({ color, lessonInfo, planType, isVisible }) => {
  const tileGenerator = () => {
    if (planType === "class" || planType === "none") {
      return (
        <div
          className={styles.lessonTile}
          style={{
            backgroundColor: color,
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.07)",
            border: "1px solid #C5E0FF30 ",
          }}
        >
          {isVisible ? (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>{lessonInfo?.lesson_type}</p>
                  {lessonInfo?.lesson_label && (
                    <Chip
                      label={lessonInfo?.lesson_label}
                      color="primary"
                      size="small"
                    />
                  )}
                </Stack>
                <p>{lessonInfo?.teacher}</p>
                <p>{lessonInfo?.classroom}</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={g} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          ) : (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>
                    {planType !== "none" && "Przedmiot"}
                  </p>
                  {lessonInfo?.lesson_label && (
                    <Chip label={"Etykieta"} color="primary" size="small" />
                  )}
                </Stack>
                <p>{planType !== "none" && "Nauczyciel"}</p>
                <p>{planType !== "none" && "Sala"}</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={"Grupa"} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          )}
        </div>
      );
    } else if (planType === "teacher") {
      return (
        <div className={styles.lessonTile} style={{ backgroundColor: color }}>
          {isVisible ? (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>{lessonInfo?.lesson_type}</p>
                  {lessonInfo?.lesson_label && (
                    <Chip
                      label={lessonInfo?.lesson_label}
                      color="primary"
                      size="small"
                    />
                  )}
                </Stack>
                <p>{lessonInfo?.class}</p>
                <p>{lessonInfo?.classroom}</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={g} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          ) : (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>Przedmiot</p>
                  {lessonInfo?.lesson_label && (
                    <Chip label="Etykieta" color="primary" size="small" />
                  )}
                </Stack>
                <p>Klasa</p>
                <p>Sala</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={"Grupa"} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          )}
        </div>
      );
    } else if (planType === "classroom") {
      return (
        <div className={styles.lessonTile} style={{ backgroundColor: color }}>
          {isVisible ? (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>{lessonInfo?.lesson_type}</p>
                  {lessonInfo?.lesson_label && (
                    <Chip
                      label={lessonInfo?.lesson_label}
                      color="primary"
                      size="small"
                    />
                  )}
                </Stack>
                <p>{lessonInfo?.class}</p>
                <p>{lessonInfo?.teacher}</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={g} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          ) : (
            <>
              <div>
                <Stack
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                >
                  <p className={styles.subject}>Przedmiot</p>
                  {lessonInfo?.lesson_label && (
                    <Chip
                      label="Etykieta"
                      color="primary"
                      size="small"
                    />
                  )}
                </Stack>
                <p>Klasa</p>
                <p>Nauczyciel</p>
              </div>

              <Stack
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"row"}
                gap={2}
              >
                {lessonInfo?.groups.map((g) => (
                  <Chip label={"Grupa"} color="secondary" size="small" />
                ))}
              </Stack>
            </>
          )}
        </div>
      );
    }
  };
  return tileGenerator();
};

export default LessonPlanTile;
