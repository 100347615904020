import React, { useState } from "react";
import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Button,
  ListSubheader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  blockingLessonsTypeList,
  formListConfig,
} from "../../requirementsConfig";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const BlockingLesson = ({isBlockingLessonAtTheSameTime}) => {
  const [formPreferenceType, setFormPreferenceType] = useState("");
  const [choosenPreferenceType, setChoosenPreferenceType] = useState(null);
  const [choosenPreferenceUnits, setChoosenPreferenceUnits] = useState([]);
  const [choosenGroups, setChoosenGroups] = useState([]);
  const [choosenLessons, setChoosenLessons] = useState([]);
  const { classes } = toJS(SchoolInfoStore);
  const isBlocking = isBlockingLessonAtTheSameTime ? "BLOCKING_LESSON_AT_THE_SAME_TIME" : "FORBIDDEN_LESSON_ON_THE_SAME_DAY";

  const handleSubmit = async () => {
    const data = {
      attType: formPreferenceType,
      type: isBlocking,
      groupsIds: choosenGroups.map((g) => ({ $oid: g })),
      ids: choosenPreferenceUnits.map((id) => ({ $oid: id })),
    };

    if (formPreferenceType === "UNIQUE_ID") {
      data.ids = choosenLessons.map(lesson => lesson._id)
    }

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp)
  };

  const handleLessonChange = (lesson) => {
    if (choosenLessons.some((l) => l._id.$oid === lesson._id.$oid)) {
      setChoosenLessons(
        choosenLessons.filter((l) => l._id.$oid !== lesson._id.$oid)
      );
      return;
    }
    setChoosenLessons([...choosenLessons, lesson]);
  };

  const handleGroupsChange = (e) => {
    const {
      target: { value },
    } = e;
    setChoosenGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleOptionsChange = (e) => {
    const {
      target: { value },
    } = e;
    setChoosenPreferenceUnits(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFormTemplateChange = (e) => {
    setChoosenPreferenceType(null);
    setChoosenPreferenceUnits([]);
    setChoosenGroups([]);
    setChoosenLessons([]);
    const preferenceType = e.target.value;
    setFormPreferenceType(preferenceType);

    switch (preferenceType) {
      case "LESSON_TYPE":
        const { subjects } = toJS(SchoolInfoStore);
        setChoosenPreferenceType(subjects.data);
        break;
      case "LESSON_LABEL":
        const { labels } = toJS(SchoolInfoStore);
        setChoosenPreferenceType(labels.data);
        break;
      case "UNIQUE_ID":
        const { lessons } = toJS(SchoolInfoStore);
        setChoosenPreferenceType(lessons.data);
        break;
      default:
        break;
    }
  };

  return (
    <Stack gap={2}>
      <FormControl fullWidth>
        <InputLabel id="chooseTypeLabel">
          Wybierz typ do zablokowania
        </InputLabel>
        <Select
          labelId="chooseTypeLabel"
          id="chooseType"
          label="Wybierz typ do zablokowania"
          onChange={handleFormTemplateChange}
        >
          {blockingLessonsTypeList.map((type) => (
            <MenuItem key={`type-${type.id}`} value={type.type}>
              {type.polishtype}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {choosenPreferenceType && formPreferenceType !== "UNIQUE_ID" && (
        <Stack>
          <FormControl fullWidth>
            <InputLabel id="chooseTypeLabel">Wybierz opcje z listy</InputLabel>
            <Select
              labelId="chooseTypeLabel"
              id="chooseType"
              multiple
              value={choosenPreferenceUnits}
              onChange={handleOptionsChange}
              label="Wybierz opcje z listy"
              MenuProps={formListConfig.MENU_PROPS}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const preference = choosenPreferenceType.find(
                      (p) => p._id.$oid === id
                    );
                    return preference ? preference.name : "";
                  })
                  .join(", ")
              }
            >
              {choosenPreferenceType.map((preference) => (
                <MenuItem
                  key={`preference-${preference._id.$oid}`}
                  value={preference._id.$oid}
                >
                  <Checkbox
                    checked={
                      choosenPreferenceUnits.indexOf(preference._id.$oid) > -1
                    }
                  />
                  {preference.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
      {choosenPreferenceUnits.length > 0 && (
        <Stack gap={2}>
          <Typography variant="h6">Wybrane opcje:</Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {choosenPreferenceUnits.map((id) => {
              const preference = choosenPreferenceType.find(
                (p) => p._id.$oid === id
              );
              return (
                <Chip
                  key={`preference-${preference._id.$oid}`}
                  label={preference.name}
                  onDelete={() => {
                    setChoosenPreferenceUnits((units) =>
                      units.filter((unit) => unit !== id)
                    );
                  }}
                />
              );
            })}
          </Stack>
          <Stack mt={2}>
            <FormControl fullWidth>
              <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
              <Select
                labelId="chooseGroupsLabel"
                id="chooseGroups"
                multiple
                value={choosenGroups}
                input={<OutlinedInput label="Wybierz grupy" />}
                onChange={handleGroupsChange}
                label="Wybierz grupy"
                MenuProps={formListConfig.MENU_PROPS}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const group = classes.data
                        .flatMap((cl) => cl.groups)
                        .find((g) => g._id.$oid === id);
                      const className = classes.data.find((cl) =>
                        cl.groups.some((g) => g._id.$oid === id)
                      )?.name;
                      return group && className
                        ? `${className} - ${group.name}`
                        : "";
                    })
                    .join(", ")
                }
              >
                {classes.data.map((cl) => [
                  <ListSubheader key={`class-${cl._id.$oid}`}>
                    {cl.name}
                  </ListSubheader>,
                  ...cl.groups.map((group) => (
                    <MenuItem
                      key={`group-${group._id.$oid}`}
                      value={group._id.$oid}
                    >
                      <Checkbox
                        checked={choosenGroups.indexOf(group._id.$oid) > -1}
                      />
                      {group.name}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
          </Stack>
          <Stack>
            <Typography variant="h6">Wybrane grupy:</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {choosenGroups.map((id) => {
                const group = classes.data
                  .flatMap((cl) => cl.groups)
                  .find((g) => g._id.$oid === id);
                const className = classes.data.find((cl) =>
                  cl.groups.some((g) => g._id.$oid === id)
                )?.name;
                return (
                  <Chip
                    key={`group-${group._id.$oid}`}
                    label={`${className} - ${group?.name}`}
                    onDelete={() => {
                      setChoosenGroups((groups) =>
                        groups.filter((group) => group !== id)
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Button variant="contained" onClick={handleSubmit}>
            Dodaj blokowanie zajęć
          </Button>
        </Stack>
      )}
      {formPreferenceType === "UNIQUE_ID" && (
        <Stack gap={1}>
          <Typography variant="h5">Lista dostępnych klas:</Typography>
          {classes?.data?.length === 0
            ? "Brak klas"
            : classes?.data
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((cl) => {
                  const classLessons = choosenPreferenceType.filter(
                    (lesson) => lesson.student_class_id.$oid === cl._id.$oid
                  );

                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Lekcje klasy: {cl.name}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack gap={1}>
                          {classLessons
                            .sort((a, b) =>
                              a.lessonType.name.localeCompare(b.lessonType.name)
                            )
                            .map((lesson) => (
                              <Stack
                                gap={2}
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                              >
                                <Typography variant="subtitle1">
                                  {lesson?.lessonType?.name}
                                </Typography>
                                <Stack
                                  gap={1}
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  {lesson?.groupDetails?.map((group) => (
                                    <Chip label={group?.name} />
                                  ))}
                                </Stack>
                                <Stack
                                  gap={1}
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  {lesson?.allowedClassroomsDetails?.map(
                                    (label, index) =>
                                      index < 2 && (
                                        <Chip
                                          label={label?.name}
                                          color="secondary"
                                        />
                                      )
                                  )}
                                </Stack>
                                <Button
                                  onClick={() =>
                                    handleLessonChange({
                                      ...lesson,
                                      className: cl.name,
                                    })
                                  }
                                >
                                  +
                                </Button>
                              </Stack>
                            ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
          {choosenLessons.length > 0 && (
            <Stack mt={2} gap={2}>
              <Typography variant="h6">Wybrane lekcje:</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {choosenLessons.map((lesson) => (
                  <Chip
                    key={`lesson-${lesson._id.$oid}`}
                    label={`${lesson.lessonType.name} - ${lesson.className}`}
                    onDelete={() => {
                      setChoosenLessons((lessons) =>
                        lessons.filter((l) => l._id.$oid !== lesson._id.$oid)
                      );
                    }}
                  />
                ))}
              </Stack>
              <Stack mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
                  <Select
                    labelId="chooseGroupsLabel"
                    id="chooseGroups"
                    multiple
                    value={choosenGroups}
                    input={<OutlinedInput label="Wybierz grupy" />}
                    onChange={handleGroupsChange}
                    label="Wybierz grupy"
                    MenuProps={formListConfig.MENU_PROPS}
                    renderValue={(selected) =>
                      selected
                        .map((id) => {
                          const group = classes.data
                            .flatMap((cl) => cl.groups)
                            .find((g) => g._id.$oid === id);
                          const className = classes.data.find((cl) =>
                            cl.groups.some((g) => g._id.$oid === id)
                          )?.name;
                          return group && className
                            ? `${className} - ${group.name}`
                            : "";
                        })
                        .join(", ")
                    }
                  >
                    {classes.data.map((cl) => [
                      <ListSubheader key={`class-${cl._id.$oid}`}>
                        {cl.name}
                      </ListSubheader>,
                      ...cl.groups.map((group) => (
                        <MenuItem
                          key={`group-${group._id.$oid}`}
                          value={group._id.$oid}
                        >
                          <Checkbox
                            checked={choosenGroups.indexOf(group._id.$oid) > -1}
                          />
                          {group.name}
                        </MenuItem>
                      )),
                    ])}
                  </Select>
                </FormControl>
              </Stack>
              <Stack gap={2} mb={2}>
                <Typography variant="h6">Wybrane grupy:</Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {choosenGroups.map((id) => {
                    const group = classes.data
                      .flatMap((cl) => cl.groups)
                      .find((g) => g._id.$oid === id);
                    const className = classes.data.find((cl) =>
                      cl.groups.some((g) => g._id.$oid === id)
                    )?.name;
                    return (
                      <Chip
                        key={`group-${group._id.$oid}`}
                        label={`${className} - ${group?.name}`}
                        onDelete={() => {
                          setChoosenGroups((groups) =>
                            groups.filter((group) => group !== id)
                          );
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          )}
          <Button variant="contained" onClick={handleSubmit}>
            Dodaj blokowanie zajęć
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default BlockingLesson;
