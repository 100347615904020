import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Button, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet-async";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import SchoolInfoStore from "../../mobx/SchoolInfoStore";
import AuthStore from "../../mobx/AuthStore";
import DataService from "../../services/DataService";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import Classes from "./ClassesComponent/Classes";
import Classrooms from "./ClassroomsComponent/Classrooms";
import Subjects from "./SubjetsComponent/Subjects";
import Teachers from "./TeachersComponent/Teachers";
import Timetables from "./TimetablesComponent/Timetables";
import Requirements from "./Requirements/Requirements";
import LoadingBar from "../LoadingBar/LoadingBar";
import Labels from "./LabelsComponent/Labels";
import Lessons from "./LessonsComponent/Lessons";
import General from "./GeneralComponent/General";
import { Instruction } from "./InstructionComponent/Instruction";
import { Alert } from "@mui/material";

import { handleGeneratePlanErrors, planSubscription } from "./profileConfig";

import {
  advancedDrawer,
  restDrawer,
  schoolPlannerDrawer,
} from "./profileConfig";

import styles from "./style.module.scss";
import Logo from "../../icons/es-logo.png";

const drawerWidth = 240;

const Profile = observer(() => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { user } = toJS(AuthStore);
  const [generatePlanError, setGeneratePlanError] = useState(false);

  const { data: schoolPlanConfig, loading } =
    toJS(SchoolInfoStore).schoolPlanConfig;

  const schoolConfig = toJS(SchoolInfoStore.schoolPlanConfig.data[0]);
  const { schoolPlanId } = toJS(SchoolInfoStore);

  const [currentJobs, setCurrentJobs] = useState(0);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logoutHandler = () => {
    AuthStore.logout();
    navigate("/");
    window.location.reload();
  };

  const generateSchedule = async (schoolPlanId) => {
    setGeneratePlanError(false);
    if (!schoolPlanId) {
      console.error("Invalid schoolPlanId: The ID is required.");
      return;
    }

    const response = await DataService.generateSchedule(schoolPlanId);
    if (response?.error) {
      const errors = handleGeneratePlanErrors(response);
      setGeneratePlanError(errors);

      const timeout = setTimeout(() => {
        setGeneratePlanError(false);
        clearTimeout(timeout);
      }, 5000);
    }
  };

  const handleGenerateSchedule = () => {
    setCurrentJobs(1);

    generateSchedule(schoolPlanId);
  };

  const fetchCurrentJobs = async () => {
    try {
      const jobs = await DataService.getCurrentJobs();
      setCurrentJobs(jobs.length);
    } catch (error) {
      console.error("Błąd przy pobieraniu aktualnych zadań:", error);
    }
  };

  useEffect(() => {
    const getSchoolPlanConfig = async () => {
      await SchoolInfoStore.getSchoolPlanConfig();
    };

    getSchoolPlanConfig();
  }, []);

  useEffect(() => {
    fetchCurrentJobs();
    const interval = setInterval(fetchCurrentJobs, 5000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <LoadingBar />;
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <NavLink to="/">
        <img
          src={Logo}
          alt="logo Easy schedule"
          style={{
            width: "200px",
            marginTop: "6px",
            marginLeft: "19px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </NavLink>

      <Divider />
      <List>
        <ListItem sx={{ justifyContent: "center" }}>
          <Typography variant="h6">
            {planSubscription(user.plan[0].plan_type)}
          </Typography>
        </ListItem>
        {schoolPlannerDrawer.map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            onClick={() => navigate(`${item.href}`)}
          >
            <ListItemButton>
              <ListItemIcon>
                {item.icon(schoolPlanConfig?.loading)}
              </ListItemIcon>
              <ListItemText primary={item.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {advancedDrawer.map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            onClick={() => navigate(`${item.href}`)}
          >
            <ListItemButton>
              <ListItemIcon>
                {item.icon(schoolPlanConfig?.loading)}
              </ListItemIcon>
              <ListItemText primary={item.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {restDrawer.map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            onClick={
              item.value === "Wyloguj"
                ? logoutHandler
                : () => navigate(`${item.href}`)
            }
          >
            <ListItemButton>
              <ListItemIcon>
                {item.value === "Wyloguj"
                  ? item.icon
                  : item.icon(schoolPlanConfig?.loading)}
              </ListItemIcon>
              <ListItemText
                primary={item.value}
                sx={item.value === "Wyloguj" ? { color: "#ed6c02" } : {}}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Timetable Planner for schools - Twój profil</title>
        <meta
          name="description"
          content="Zarządzaj swoim profilem i planem lekcji w Generatorze planów lekcji dla szkół. Skorzystaj z narzędzi do tworzenia, edytowania i przeglądania planów lekcji oraz zarządzania danymi szkoły."
        />
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {(schoolConfig?.name || schoolPlanConfig?.schoolname) ??
                  "Ustaw nazwę szkoły w zakładce Ogólne"}
              </Typography>
            </Box>
            {schoolConfig?.lessons?.length < 1 || currentJobs > 0 ? (
              <>
                <div>
                  <Tooltip title="Aby wygenerować plan, najpierw musisz dodać lekcje. Przejdź do zakładki 'Lekcje' i dodaj odpowiednie zajęcia.">
                    <IconButton>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <Button
                    sx={{
                      background: "white",
                      border: "1px solid white",
                      marginLeft: "8px",
                      "&:hover": {
                        color: "white",
                        border: "1px solid white",
                      },
                    }}
                    onClick={handleGenerateSchedule}
                    disabled
                  >
                    Generuj plan
                  </Button>
                  <Box>
                    {currentJobs > 0 ? (
                      <Typography
                        variant="body1"
                        position="absolute"
                        color="black"
                        sx={{ bottom: "-30px" }}
                      >
                        Trwa generowanie planu
                      </Typography>
                    ) : null}
                  </Box>
                </div>
              </>
            ) : (
              <Button
                sx={{
                  background: "white",
                  border: "1px solid white",
                  "&:hover": {
                    color: "white",
                    border: "1px solid white",
                  },
                }}
                onClick={handleGenerateSchedule}
              >
                Generuj plan
              </Button>
            )}
          </Toolbar>
          {generatePlanError && (
            <Alert severity="warning" icon={false}>
              {generatePlanError.map((err) => err)}
            </Alert>
          )}
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            pt: 6,
            width: { md: `calc(100% - ${drawerWidth}px)` },
            justifyContent: "center",
          }}
        >
          <Toolbar />
          {schoolPlanConfig.loading ? (
            <LoadingBar />
          ) : (
            <div className={styles.wrapper}>
              <Routes>
                <Route path="/" element={<Instruction />} />
                <Route path="/classes" element={<Classes />} />
                <Route path="/timetables/*" element={<Timetables />} />
                <Route path="/subjects" element={<Subjects />} />
                <Route path="/teachers" element={<Teachers />} />
                <Route path="/classrooms" element={<Classrooms />} />
                <Route path="/labels" element={<Labels />} />
                <Route path="/lessons" element={<Lessons />} />
                <Route path="/requirements" element={<Requirements />} />
                <Route path="/general" element={<General />} />
              </Routes>
            </div>
          )}
        </Box>

        <div className={styles.feedbackBoxMain}>
          <FeedbackForm />
        </div>
      </Box>
    </>
  );
});

export default Profile;
