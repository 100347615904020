import PreferencesForm from "./RequirementForms/PreferencesForm/PreferencesForm";
import BlockingLesson from "./RequirementForms/BlockingLessonATST/BlockingLesson";
import EdgeLessonForm from "./RequirementForms/EdgeLessonForm/EdgeLessonForm";
import LessonDistributionForm from "./RequirementForms/LessonDistributionForm/LessonDistributionForm";
import LabeledLessonsPerDayForm from "./RequirementForms/LabeledLessonsPerDayForm/LabeledLessonsPerDayForm";
import LessonStartForm from "./RequirementForms/LessonStartForm/LessonStartForm";
import { MenuItem, Tooltip } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export const typesList = [
  {
    id: 1,
    type: "PREFERENCE",
    polishtype: "Preferencje",
    isFree: false,
  },
  {
    id: 2,
    type: "BLOCKING_LESSON_AT_THE_SAME_TIME",
    polishtype: "Blokowanie zajęć w tym samym czasie",
    isFree: true,
  },
  {
    id: 3,
    type: "FORBIDDEN_LESSON_ON_THE_SAME_DAY",
    polishtype: "Zabronione zajęcia w tym samym dniu",
    isFree: false,
  },
  {
    id: 4,
    type: "EDGE_LESSON",
    polishtype: "Zajęcia na krawędziach",
    isFree: false,
  },
  {
    id: 5,
    type: "LESSON_DISTRIBUTION",
    polishtype: "Rozkład zajęć",
    isFree: true,
  },
  {
    id: 6,
    type: "LABELED_LESSONS_PER_DAY",
    polishtype: "Zaetykietowane zajęcia w danym dniu",
    isFree: false,
  },
  {
    id: 7,
    type: "LESSON_START",
    polishtype: "Początek zajęć",
    isFree: false,
  },
];

export const checkPlan = (planType) => {
  if (planType === "FREE") {
    const plan = typesList.map((type) => (
      <MenuItem key={`type-${type.id}`} value={type.type}>
        {type.polishtype}{" "}
        {!type.isFree ? (
          <Tooltip title="To wymaganie jest dostępne w pełnym planie. Można go użyć, jednak plan zajęć pozostanie niewidoczny. Widoczne będą tylko wskaźniki jakości.">
            <PriorityHighIcon sx={{color: 'red'}}/>
          </Tooltip>
        ) : null}
      </MenuItem>
    ));

    return plan;
  } else if (planType === "BASIC") {
    return typesList.map((type) => (
      <MenuItem key={`type-${type.id}`} value={type.type}>
        {type.polishtype}
      </MenuItem>
    ));
  }
};

export const generateCorrectForm = (requirementType) => {
  switch (requirementType) {
    case "PREFERENCE":
      return <PreferencesForm />;
    case "BLOCKING_LESSON_AT_THE_SAME_TIME":
      return <BlockingLesson isBlockingLessonAtTheSameTime={true} />;
    case "FORBIDDEN_LESSON_ON_THE_SAME_DAY":
      return <BlockingLesson isBlockingLessonAtTheSameTime={false} />;
    case "EDGE_LESSON":
      return <EdgeLessonForm />;
    case "LESSON_DISTRIBUTION":
      return <LessonDistributionForm />;
    case "LABELED_LESSONS_PER_DAY":
      return <LabeledLessonsPerDayForm />;
    case "LESSON_START":
      return <LessonStartForm />;
    default:
      return null;
  }
};

export const preferencesTypeList = [
  {
    id: 1,
    type: "TEACHER",
    polishtype: "Nauczyciel",
  },
  {
    id: 2,
    type: "CLASSROOM",
    polishtype: "Sala",
  },
  {
    id: 3,
    type: "GROUP",
    polishtype: "Grupa",
  },
];

export const blockingLessonsTypeList = [
  {
    id: 1,
    type: "LESSON_TYPE",
    polishtype: "Przedmiot",
  },
  {
    id: 2,
    type: "LESSON_LABEL",
    polishtype: "Etykieta",
  },
  {
    id: 3,
    type: "UNIQUE_ID",
    polishtype: "Lekcja",
  },
];

export const edgeTypes = [
  {
    id: 1,
    type: "FIRST",
    polishtype: "Pierwsza krawędź",
  },
  {
    id: 2,
    type: "LAST",
    polishtype: "Ostatnia krawędź",
  },
  {
    id: 3,
    type: "BOTH",
    polishtype: "Obie krawędzie",
  },
];

export const distributionTypes = [
  {
    id: 1,
    type: "EVEN",
    polishtype: "Równomiernie",
  },
  {
    id: 2,
    type: "SPECIFIC",
    polishtype: "Specyficznie",
  },
  {
    id: 3,
    type: "RANGE",
    polishtype: "Zakres",
  },
];

export const formListConfig = {
  ITEM_HEIGHT: 48,
  ITEM_PADDING_TOP: 8,
  MENU_PROPS: {
    PaperProps: {
      style: {
        get maxHeight() {
          return (
            formListConfig.ITEM_HEIGHT * 4.5 + formListConfig.ITEM_PADDING_TOP
          );
        },
        width: 250,
      },
    },
  },
};
