import React, { useEffect } from "react";
import {
  getEvaluationRate,
  getBestEvaluations,
  evaluationTime,
} from "./singlePlanConfig";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SinglePlan = ({ plan, index }) => {
  const navigate = useNavigate();
  const { evaluations } = plan.best_detailed_evaluation;
  const time = evaluationTime(plan.time);

  const navigationHandler = () => {
    if (!plan.visible) return navigate(`/purchase`);
    navigate(`${plan._id.$oid}`);
  };

  const buttonDisplay = () => {
    if (!plan.visible) return "Przejdź na wyższy plan";
    return "Szczegóły planu";
  };

  useEffect(() => {
    getBestEvaluations(evaluations);
  }, []);

  const generationDate = new Date(plan.creation_date).toLocaleString();

  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      id="singlePlan"
      boxShadow={3}
      mt={2}
      p={6}
      gap={2}
    >
      <Stack
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        flexWrap={"wrap"}
        gap={2}
      >
        <Stack display={"flex"} justifyContent={"space-between"} gap={2}>
          <Stack sx={{ filter: plan.visible ? "" : "blur(4px)" }} gap={1}>
            <Typography variant="h4">Plan #{index}</Typography>
            <Typography variant="h6">Ilość kolizji: {getEvaluationRate(plan.best_evaluation)}</Typography>
            <Typography variant="h6">Czas generacji planu: {time}</Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={navigationHandler}
            color={plan.visible ? "primary" : "secondary"}
          >
            {buttonDisplay()}
          </Button>
        </Stack>
        <Stack
          display={"flex"}
          gap={1}
          minWidth={"350px"}
        >
          <Stack>
            <Typography variant="h4">Wystąpienia kolizji</Typography>
          </Stack>
          {plan.best_evaluation !== 0 ? getBestEvaluations(evaluations) : <Typography variant="caption">Brak kolizji</Typography>}
        </Stack>
        <Stack>
          <Typography variant="caption">{generationDate}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SinglePlan;
