import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export const Instruction = () => {
  return (
    <Box sx={{ width: "90%" }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Instrukcja używania aplikacji
      </Typography>

      <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>
        Konfiguracja szkoły
      </Typography>
      <Typography variant="body1" gutterBottom>
        Zachowaj poniższą kolejność konfigurowania swojej szkoły.
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary="1. Tworzenie sal lekcyjnych"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Otwórz sekcję 'Sale lekcyjne'.
                </Typography>
                <Typography component="span">
                  2. Wprowadź nazwy wszystkich sal lekcyjnych.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Tworzenie przedmiotów"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Otwórz sekcję 'Przedmioty'.
                </Typography>
                <Typography component="span">
                  2. Wprowadź nazwy wszystkich przedmiotów. Najbardziej
                  popularne utworzyliśmy za Ciebie.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Tworzenie etykiet"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span" sx={{ mb: 2 }}>
                  Etykiety służą do oznaczenia lekcji, np. "TRUDNA". Są
                  przydatne w konfiguracji wymagań na plan lekcyjny,
                  umożliwiając ustawienie maksymalnej ilości lekcji z daną
                  etykietą w ciągu dnia i wiele innych. Więcej możliwości
                  poznasz później. Etykiety są opcjonalne i nie musisz ich
                  używać.
                </Typography>
                <Typography component="span">
                  1. Otwórz sekcję 'Etykiety'.
                </Typography>
                <Typography component="span">
                  2. Wprowadź nazwy etykiet. Etykiety służą do oznaczenia
                  lekcji, np. "TRUDNA", i są opcjonalne.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. Tworzenie nauczycieli"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Otwórz sekcję 'Nauczyciele'.
                </Typography>
                <Typography component="span">
                  2. Wprowadź imiona i nazwiska nauczycieli.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5. Tworzenie klas"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Przejdź do sekcji 'Klasy'.
                </Typography>
                <Typography component="span">
                  2. Wprowadź nazwę klasy.
                </Typography>
                <Typography component="span">
                  3. Możesz stworzyć grupy w ramach danej klasy.
                </Typography>
                <Typography component="span" sx={{ marginLeft: 4 }}>
                  ○ Jeśli niektóre lekcje mogą odbywać się równocześnie (np. WF
                  lub język angielski), utwórz dwie grupy wpisując dowolne nazwy
                  oddzielone przecinkiem, np. A, B
                </Typography>
                <Typography component="span" sx={{ marginLeft: 4 }}>
                  ○ Jeśli nie utworzysz grup, automatycznie zostanie utworzona
                  jedna grupa reprezentująca całą klasę.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6. Tworzenie lekcji"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Przejdź do sekcji 'Lekcje'.
                </Typography>
                <Typography component="span">
                  2. Rozwiń wybraną klasę.
                </Typography>
                <Typography component="span">3. Wybierz przedmiot.</Typography>
                <Typography component="span">
                  4. Określ, ile slotów zajmuje lekcja (najczęściej 1 godzina).
                </Typography>
                <Typography component="span">
                  5. Ustal liczbę lekcji danego typu w tygodniu.
                </Typography>
                <Typography component="span">
                  6. Wybierz nauczyciela.
                </Typography>
                <Typography component="span">
                  7. (Opcjonalnie) Dodaj etykietę.
                </Typography>
                <Typography component="span">
                  8. Wybierz grupy, których dotyczy lekcja.
                </Typography>
                <Typography component="span">
                  9. Określ dozwolone sale.
                </Typography>
              </Box>
            }
          />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>
        Kopiowanie lekcji
      </Typography>
      <Typography variant="body1" gutterBottom fontWeight={700}>
        Aby przyspieszyć proces tworzenia lekcji dostępne są dwie funkcje
        kopiowania.
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Kopiowanie lekcji z jednej klasy do drugiej oraz edycji poszczególnych elementów, np. nauczyciela lub dozwolone sale lekcyjne." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Kopiowanie sal lekcyjnych z lekcji do lekcji." />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>
        Konfiguracja wymagań
      </Typography>

      <Typography variant="body1" gutterBottom fontWeight={700}>
        Aplikacja może nie generować idealnych planów lekcyjnych w przypadku
        sprzecznych wymagań lub zbyt rygorystycznych ograniczeń. Zachęcamy do
        stopniowego zwiększania wymagań, aby uzyskać jak najlepsze wyniki.
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Rozkład lekcji"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Można wybrać 3 typy rozkładu:
                </Typography>
                <Typography component="span" sx={{ marginLeft: 4 }}>
                  ○ Tyle samo lekcji każdego dnia.
                </Typography>
                <Typography component="span" sx={{ marginLeft: 4 }}>
                  ○ Określona ilość lekcji w poszczególne dni. Przykład: od
                  poniedziałku do czwartku 6 lekcji, w piątek 4 lekcje
                </Typography>
                <Typography component="span" sx={{ marginLeft: 4 }}>
                  ○ Zakres ilości lekcji w danym dniu. Przykład: poniedziałek od
                  5 do 7 lekcji.
                </Typography>
                <Typography component="span">
                  2. Konfiguracja możliwa dla poszczególnych klas i grup.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. Rozpoczęcie lekcji o danej godzinie"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Można wybrać godzinę rozpoczęcia lekcji w danym dniu.
                </Typography>
                <Typography component="span">
                  2. Konfiguracja możliwa dla poszczególnych klas i grup.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="3. Preferencje"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Możliwość konfiguracji preferencji dla wybranych
                  nauczycieli, sal lekcyjnych oraz grup
                </Typography>
                <Typography component="span">
                  2. Ustalenie godzin, w których mają się odbywać lekcje
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="4. Blokowanie odbywania się lekcji w tym samym czasie"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Służy do blokowania odbywania się danych lekcji w tym samym
                  czasie.
                </Typography>
                <Typography component="span">
                  2. Wybór lekcji na podstawie przedmiotu, etykiety lub
                  konkretnej lekcji.
                </Typography>
                <Typography component="span">
                  3. Należy skonfigurować dla każdej klasy osobno. Konfiguracja
                  dla wszystkich klas jednego warunku, oznacza, że lekcje między
                  różnymi klasami nie będą odbywały się w tym samym czasie. Co
                  może utrudnić znalezienie optymalnego planu zajęć.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5. Blokowanie odbywania się lekcji w tym samym dniu"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Służy do blokowania odbywania się danych lekcji w tym samym
                  dniu.
                </Typography>
                <Typography component="span">
                  2. Wybór lekcje na podstawie przedmiotu, etykiety lub
                  konkretnej lekcji.
                </Typography>
                <Typography component="span">
                  3. Konfiguracja możliwa dla wszystkich klas jednocześnie lub
                  osobno w zależności od potrzeb.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6. Ograniczenie ilości lekcji o danej etykiecie w dniu"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Służy do ograniczenia ilości lekcji o danej etykiecie w
                  ciągu dnia.
                </Typography>
                <Typography component="span">
                  2. Konfiguracja możliwa dla wszystkich klas jednocześnie lub
                  osobno w zależności od potrzeb.
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="7. Wybór lekcji skrajnych"
            primaryTypographyProps={{
              fontWeight: "700",
              fontSize: "1.125rem",
              marginBottom: "8px",
            }}
            secondary={
              <Box
                component="div"
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Typography component="span">
                  1. Służy do wyboru skrajnych lekcji.
                </Typography>
                <Typography component="span">
                  2. Wybór lekcje na podstawie przedmiotu, etykiety lub
                  konkretnej lekcji.
                </Typography>
                <Typography component="span">
                  3. Można wybrać: pierwszą lekcję, ostatnią lekcję lub obie.
                </Typography>
                <Typography component="span">
                  4. Konfiguracja możliwa dla wszystkich klas jednocześnie lub
                  osobno w zależności od potrzeb.
                </Typography>
              </Box>
            }
          />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h4" gutterBottom sx={{ pt: 2, mb: 2 }}>
        Popularne przypadki użycia
      </Typography>
      <Grid container spacing={0} sx={{ mb: 8 }}>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }} fontWeight={700}>
            Sytuacja
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }} fontWeight={700}>
            Rozwiązanie
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            W sali gimnastycznej może odbywać się kilka lekcji.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Stworzenie odrębnych sal o podobnej nazwie.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            W sali gimnastycznej może odbywać się kilka lekcji, ale tylko dla
            określonych grup. Inne grupy zajmują całą salę.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Stworzenie odrębnych sal o podobnej nazwie i zablokowanie
            wykonywania się określonych lekcji w tym samym czasie. Np. WF dużej
            grupy nie może się wykonywać z WF pozostałych grup.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Dana lekcja (np. WF na basenie) może odbywać się tylko w określonym
            czasie.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Można zdefiniować preferencje sali i spowodować, że lekcja w tej
            sali może odbywać się tylko w określonym czasie.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Dana lekcja (np. język polski) raz w tygodniu powinna zajmować 2h
            lekcyjne, zamiast jednej.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Można jedną lekcję skonfigurować z rozmiarem lekcji jako 2, a
            pozostałe jako 1.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Każda klasa powinna mieć WF na sali gimnastycznej jeden raz w
            tygodniu.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Można dodać jedną lekcję typu WF z możliwą tylko salą gimnastyczną,
            a pozostałe godziny z pozostałymi salami do WF.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Lekcja WF i angielski nie mogą odbywać się w tym samym czasie, bo
            grupy mają wspólnych uczniów.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ddd" }}>
          <Typography variant="body1" sx={{ padding: 1 }}>
            Można zablokować wykonywanie się WF i angielskiego w tym samym
            momencie dla wybranych grup. Należy pamiętać, że ten warunek trzeba
            skonfigurować osobno dla każdej klasy. W inny wypadku wszystkie
            lekcje typu WF i angielski ze wszystkich klas nie będą mogły odbywać
            się w tym samym czasie, co może uniemożliwić znalezienie optymalnego
            planu zajęć.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
