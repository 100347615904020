import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import SchoolInfoStore from "../../../mobx/SchoolInfoStore";

const General = observer(() => {
  const [name, setName] = useState("");
  const [daysInWeek, setDaysInWeek] = useState(5);
  const [daysNames, setDaysNames] = useState([]);
  const [slotsPerDay, setSlotsPerDay] = useState(9);
  const [slotsTimes, setSlotsTimes] = useState([]);

  const schoolConfig = toJS(SchoolInfoStore.schoolPlanConfig.data[0]);

  useEffect(() => {
    if (schoolConfig) {
      setName(schoolConfig.name);
      setDaysInWeek(schoolConfig.numOfDays || 5);
      setDaysNames(
        schoolConfig.daysNames || [
          "Poniedziałek",
          "Wtorek",
          "Środa",
          "Czwartek",
          "Piątek",
          "Sobota",
        ]
      );
      setSlotsPerDay(schoolConfig.numOfSlotsPerDay || 9);

      const formatTime = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes}`;
      };

      const times =
        schoolConfig.slotsNames?.map((slot) => {
          const [startTime, endTime] = slot.split("-");
          return {
            startTime: formatTime(startTime.trim()),
            endTime: formatTime(endTime.trim()),
          };
        }) || [];

      setSlotsTimes(times);
    }
  }, []);

  const handleSchoolNameChange = (name) => {
    setName(name);
  };

  const handleDaysPerWeekChange = (daysInWeek) => {
    setDaysInWeek(daysInWeek);
  };

  const handleDaysNamesChange = (index, value) => {
    const newDaysNames = [...daysNames];
    newDaysNames[index] = value;
    setDaysNames(newDaysNames);
  };

  const handleSlotTimeChange = (index, value) => {
    const newSlotsTimes = [...slotsTimes];
    newSlotsTimes[index].startTime = value;

    const [hours, minutes] = value.split(":").map(Number);
    const endTime = new Date();
    endTime.setHours(hours);
    endTime.setMinutes(minutes + 45);

    newSlotsTimes[index].endTime = endTime.toTimeString().slice(0, 5);
    setSlotsTimes(newSlotsTimes);
  };

  const handleSlotsPerDayChange = (value) => {
    setSlotsPerDay(value);
    setSlotsTimes((prevTimes) => {
      const updatedTimes = prevTimes.slice(0, value);
      if (value > updatedTimes.length) {
        const lastSlot = updatedTimes[updatedTimes.length - 1] || {
          startTime: "08:00",
          endTime: "08:45",
        };
        const [lastHours, lastMinutes] = lastSlot.startTime
          .split(":")
          .map(Number);
        const newSlots = Array.from(
          { length: value - updatedTimes.length },
          (_, i) => {
            const startTime = new Date();
            startTime.setHours(lastHours, lastMinutes + (i + 1) * 45);
            const newStartTime = startTime.toTimeString().slice(0, 5);

            const endTime = new Date(startTime);
            endTime.setMinutes(endTime.getMinutes() + 45);
            const newEndTime = endTime.toTimeString().slice(0, 5);

            return {
              startTime: newStartTime,
              endTime: newEndTime,
            };
          }
        );
        return [...updatedTimes, ...newSlots];
      }
      return updatedTimes;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedConfig = {
      name: name,
      numOfDays: daysInWeek,
      daysNames: daysNames,
      numOfSlotsPerDay: slotsPerDay,
      slotsNames: slotsTimes.map((slot) => `${slot.startTime}-${slot.endTime}`),
    };

    await SchoolInfoStore.updateSchoolPlanConfig(updatedConfig);

    window.location.reload();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, mb: 2 }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Ustawienia ogólne
      </Typography>
      <Tooltip
        title="Aby zapisać ustawienia, kliknij przycisk ZAPISZ, znajdujący się na dole strony "
        placement="right"
      >
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <TextField
        label="Nazwa szkoły"
        value={name}
        onChange={(e) => handleSchoolNameChange(e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Ilość dni w tygodniu"
        type="number"
        value={daysInWeek}
        onChange={(e) => handleDaysPerWeekChange(Number(e.target.value))}
        fullWidth
        margin="normal"
      />

      <Grid container spacing={2}>
        {Array.from({ length: daysInWeek }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TextField
              label={`Dzień ${index + 1}`}
              value={daysNames[index] || ""}
              onChange={(e) => handleDaysNamesChange(index, e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        ))}
      </Grid>

      <TextField
        label="Ilość slotów w ciągu dnia"
        type="number"
        value={slotsPerDay}
        onChange={(e) => handleSlotsPerDayChange(Number(e.target.value))}
        fullWidth
        margin="normal"
      />

      <Grid container spacing={2}>
        {slotsTimes.map((slot, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TextField
              label={`Slot ${index + 1} - Godzina Rozpoczęcia`}
              type="time"
              value={slot.startTime}
              onChange={(e) => handleSlotTimeChange(index, e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
            <TextField
              label={`Slot ${index + 1} - Godzina zakończenia`}
              type="time"
              value={slot.endTime}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
              disabled
            />
          </Grid>
        ))}
      </Grid>

      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Zapisz
      </Button>
    </Box>
  );
});

export default General;
