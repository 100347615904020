import React from "react";
import styles from "./Navigation.module.scss";
import { Container, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import MainNav from "./MainNav";
import UserNav from "./UserNav";

const Navigation = observer(() => {
  const navigate = useNavigate();
  const { isUserLogged, loading, user } = AuthStore;
  const schoolConfig = toJS(user?.schoolPlanConfigs.name);
  const schoolName = schoolConfig;

  return (
    <nav className={styles.navigation}>
      <Container>
        <UserNav
          loading={loading}
          isUserLogged={isUserLogged}
          schoolName={schoolName}
          navigate={navigate}
        />
        <span className={styles.divider}>
          <Divider />
        </span>
        <MainNav
          loading={loading}
          isUserLogged={isUserLogged}
          schoolName={schoolName}
          navigate={navigate}
        />
      </Container>
    </nav>
  );
});

export default Navigation;
