import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  Container,
  Button,
  TextField,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import CustomAlert from "../Alert/CustomAlert";
import { Helmet } from "react-helmet-async";
import AuthStore from "../../mobx/AuthStore";
import Logo from "../../icons/LogoES.svg";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleRecoveryPassword = async () => {
    setError(false);
    setSuccess(false);

    const response = await AuthStore.recoveryPassword(email);

    if (response.error) {
      setError("Wystąpił błąd podczas resetowania hasła");
      return;
    }

    setSuccess(
      "Instrukcje dotyczące resetowania hasła zostały wysłane na podany adres email."
    );
  };

  return (
    <>
      <Helmet>
        <title>Timetable Planner for schools - Resetowanie hasła</title>
        <meta
          name="description"
          content="Resetowanie hasła do Generatora planów lekcji dla szkół - Uzyskaj pomoc przy odzyskiwaniu dostępu do konta."
        />
      </Helmet>
      <Container>
        <div className={styles.auth}>
          <div>
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo Easy schedule"
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
              />
            </NavLink>
          </div>
          <div className={styles.form}>
            <div className={styles.formWrapper}>
              <Stack mb={2}>
                <Typography variant="h3" fontWeight={300} textAlign={"center"}>
                  Resetowanie hasła
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  fontWeight={300}
                  p={2}
                >
                  Podaj swój adres email, aby otrzymać instrukcje resetowania
                  hasła.
                </Typography>
                {error && <CustomAlert status="error" message={error} />}
                {success && <CustomAlert status="success" message={success} />}
              </Stack>

              <Box component="form">
                <Stack gap={2} mb={2}>
                  <TextField
                    label="Email"
                    id="email"
                    placeholder="jankowalski@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </Stack>
                <Button variant="contained" onClick={handleRecoveryPassword}>
                  Resetuj hasło
                </Button>
              </Box>
              <p>
                Pamiętasz hasło? <NavLink to="/login">Logowanie</NavLink>.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PasswordReset;
