import React from "react";
import styles from "./styles.module.scss";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";
import {
  Container,
  Button,
  TextField,
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomAlert from "../Alert/CustomAlert";
import { authPasswordChecker } from "../../services/authPasswordChecker";
import { Helmet } from "react-helmet-async";
import Logo from "../../icons/es-logo.png";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleRegister = async () => {
    setError(false);

    const isPasswordCorrect = authPasswordChecker(password, repeatPassword);
    if (isPasswordCorrect !== true) {
      setError(isPasswordCorrect);
      return;
    }

    if (!isAccepted) {
      setError(
        "Akceptacja polityki prywatności i regulaminu jest obowiązkowa."
      );
      return;
    }

    const registerData = {
      email,
      password,
    };

    const response = await AuthStore.register(registerData);

    if (response.error) {
      if (response.status === 409) {
        setError("Email już istnieje w bazie danych");
        return;
      }
      setError("Wystąpił błąd podczas rejestracji");
      return;
    }

    setIsSent(true);
  };
  const handleResendToken = () => {
    void AuthStore.resendToken(email);
  };

  return (
    <>
      <Helmet>
        <title>Timetable Planner for schools - register</title>
        <meta
          name="description"
          content="Zarejestruj się do Generatora planów lekcji dla szkół - Efektywne tworzenie i zarządzanie planami lekcji z naszym prostym w użyciu narzędziem."
        />
      </Helmet>
      <Container>
        <div className={styles.auth}>
          <div>
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo Easy schedule"
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
              />
            </NavLink>
          </div>
          <div className={styles.form}>
            <div className={styles.formWrapper}>
              <Stack mb={2}>
                <Typography variant="h3" fontWeight={300} textAlign={"center"}>
                  Rejestracja
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  fontWeight={300}
                  p={2}
                >
                  Witaj! Zarejestruj się aby uzyskać dostęp do zasobów.
                </Typography>
                {error && <CustomAlert status="error" message={error} />}
              </Stack>

              {isSent ? (
                <>
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    fontWeight={300}
                    p={2}
                  >
                    Link aktywacyjny został wysłany na podany adres e-mail. (Jeśli nie widzisz wiadomości, sprawdź folder spam)
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    fontWeight={300}
                    p={2}
                  >
                    Nie dostałeś maila?{" "}
                    <Button onClick={handleResendToken} variant="text">
                      Wyślij link ponownie
                    </Button>
                  </Typography>
                </>
              ) : (
                <Box component="form">
                  <Stack gap={2} mb={2}>
                    <TextField
                      label="Email"
                      id="email"
                      placeholder="jankowalski@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Hasło"
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Powtórz hasło"
                      id="repeatPassword"
                      type="password"
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      fullWidth
                    />
                    <FormControlLabel
                      required
                      control={<Checkbox />}
                      onChange={(e) => setIsAccepted((prevState) => !prevState)}
                      value={isAccepted}
                      label={
                        <>
                          Rejestrując się, akceptujesz{" "}
                          <NavLink
                            to="/privacypolicy"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            politykę prywatności
                          </NavLink>{" "}
                          i{" "}
                          <NavLink
                            to="/regulations"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            regulamin
                          </NavLink>
                        </>
                      }
                    />
                  </Stack>
                  <Button variant="contained" onClick={handleRegister}>
                    Zarejestruj się
                  </Button>
                </Box>
              )}
              <p>
                Masz już konto? <NavLink to="/login">Logowanie</NavLink>.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Register;
