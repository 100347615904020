import React from 'react';
import { Stack, Tooltip, Chip, IconButton } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";

const AllowedClassroomsRow = ({classLesson, setIsAddModalOpen, styles, handleRemoveClassroom, lessonIdToCopy, handleCopyClassrooms, handlePasteClassrooms}) => {
  return (
    <Stack
    alignItems="center"
    flexDirection="row"
    gap={1}
    flexWrap="wrap"
  >
    {classLesson?.allowedClassroomsDetails?.length === 0 ? (
      <Tooltip
        title="Proszę dodać sale lekcyjne"
        onClick={() => setIsAddModalOpen(true)}
      >
        <WarningIcon color="error" />
      </Tooltip>
    ) : (
      classLesson.allowedClassroomsDetails?.map((classroom) => (
        <div
          className={styles.chipWrapper}
          key={`classroom-${classroom._id.$oid}`}
        >
          <Chip
            label={classroom?.name}
            onDelete={
              classLesson?.allowedClassroomsDetails?.length === 1
                ? undefined
                : () => handleRemoveClassroom(classroom._id.$oid)
            }
            classes={{ root: styles.chip }}
          />
        </div>
      ))
    )}
    <Stack
      alignItems="center"
      flexDirection="row"
      display="inline-flex"
      sx={{ ml: "auto" }}
    >
      {!lessonIdToCopy ||
      lessonIdToCopy.$oid !== classLesson._id.$oid ? (
        <Tooltip placement="top" title="Skopiuj sale">
          <IconButton
            onClick={() => handleCopyClassrooms(classLesson._id)}
            size="small"
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Skopiowano sale">
          <CheckIcon />
        </Tooltip>
      )}
      {lessonIdToCopy &&
      lessonIdToCopy.$oid !== classLesson._id.$oid ? (
        <Tooltip placement="top" title="Wklej skopiowane sale">
          <IconButton
            onClick={() => handlePasteClassrooms(classLesson._id)}
          >
            <ContentPasteGoIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  </Stack>
  )
}

export default AllowedClassroomsRow