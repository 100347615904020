import React from 'react';
import { Select, MenuItem } from '@mui/material';

const LabelRow = ({label, handleLabelChange, labels}) => {
  return (
    <Select
    labelId="label"
    id="label"
    value={label?._id?.$oid || ""}
    label="Etykieta"
    onChange={handleLabelChange}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 48 * 4.5 + 8,
          width: 250,
        },
      },
    }}
  >
    {labels.data.map((label) => (
      <MenuItem key={label._id.$oid} value={label._id.$oid}>
        {label.name}
      </MenuItem>
    ))}
  </Select>
  )
}

export default LabelRow