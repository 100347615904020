import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./SiteNotFound.module.scss";
import { Button, Typography } from "@mui/material";

export const SiteNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.notFound}>
      <div className={styles.notFoundBackdrop}></div>

      <div className={styles.notFoundContent}>
        <h1 className={styles.notFoundHeader}>404</h1>
        <Typography
          variant="h2"
          sx={{ mb: 2 }}
          className={styles.notFoundSubheader}
        >
          Strony nie znaleziono
        </Typography>

        <p className={styles.notFoundText}>
          Strona o tym adresie URL nie istnieje!
        </p>

        <Link to={`/`}>
          <Button className={styles.notFoundButton} variant="contained">
            WRÓĆ NA STRONĘ GŁÓWNĄ
          </Button>
        </Link>
      </div>
    </div>
  );
};
