import React from "react";
import { Select, MenuItem } from "@mui/material";

const TeacherAmountRow = ({ teacher, handleTeacherChange, teachers }) => {
  return (
    <Select
      labelId="teacher"
      id="teacher"
      value={teacher?._id?.$oid || ""}
      label="Nauczyciel"
      onChange={handleTeacherChange}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      }}
    >
      {teachers.data.map((teacher) => (
        <MenuItem key={teacher._id.$oid} value={teacher._id.$oid}>
          {teacher.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TeacherAmountRow;
