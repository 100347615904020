import React from "react";
import { Mail } from "lucide-react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/material";
import Logo from "../../icons/es-logo.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.container}>
          <Box
            display={{ xs: "flex", md: "flex" }}
            flexDirection={{ xs: "column", md: "row" }}
            width="100%"
            justifyContent="space-between"
            alignItems={"center"}
            gap={2}
          >
            <div className={styles.content}>
              <img src={Logo} alt="EasySchedule" />
              <div className={styles.contact}>
                <a href="mailto: kontakt@easyschedule.pl">
                  <Mail strokeWidth={1} />
                  kontakt@easyschedule.pl
                </a>
              </div>
            </div>
            <div className={styles.legal}>
              <Link to="/regulations" target="_blank">
                Regulamin aplikacji
              </Link>
              <Link to="/privacypolicy" target="_blank">
                Polityka Prywatności
              </Link>
              <Link to="/sla" target="_blank">
                SLA
              </Link>
              <Link to="/license" target="_blank">
                Umowa licencyjna
              </Link>
            </div>
          </Box>
          <div className={styles.copy}>
            <p>2024 &copy; EasySchedule. Wszelkie prawa zastrzeżone.</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
