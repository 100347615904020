import React from 'react';
import { MenuItem, Select } from '@mui/material';

const LessonAmount = ({handleAmountChange, amount, availableAmount}) => {
  return (
    <Select
    labelId="amount"
    id="amount"
    value={amount}
    label="Ilość lekcji w tyg."
    onChange={handleAmountChange}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 48 * 4.5 + 8,
          width: 250,
        },
      },
    }}
  >
    {availableAmount.map((amount) => (
      <MenuItem key={amount} value={amount}>
        {amount}
      </MenuItem>
    ))}
  </Select>
  )
}

export default LessonAmount