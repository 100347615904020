import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Button,
  ListSubheader,
} from "@mui/material";
import { preferencesTypeList } from "../../requirementsConfig";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";
import { formListConfig } from "../../requirementsConfig";
import SlotsNet from "./SlotsNet";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const PreferencesForm = () => {
  const [formPreferenceType, setFormPreferenceType] = useState("");
  const [selectedPreferenceItems, setSelectedPreferenceItems] = useState(null);
  const [choosenPreferenceUnits, setChoosenPreferenceUnits] = useState([]);
  const [choosenTiles, setChoosenTiles] = useState([]);
  const { numOfDays: days, numOfSlotsPerDay: slots } = toJS(
    SchoolInfoStore?.schoolPlanConfig?.data?.[0]
  );

  const handleOptionsChange = (e) => {
    const {
      target: { value },
    } = e;
    setChoosenPreferenceUnits(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleTileChange = (tile) => {
    if (choosenTiles.includes(tile)) {
      setChoosenTiles(choosenTiles.filter((t) => t !== tile));
      return;
    }
    setChoosenTiles([...choosenTiles, tile]);
  };

  const handleFormTemplateChange = (e) => {
    setSelectedPreferenceItems(null);
    setChoosenPreferenceUnits([]);
    const preferenceType = e.target.value;
    setFormPreferenceType(preferenceType);
    switch (preferenceType) {
      case "TEACHER":
        const { teachers } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(teachers.data);
        break;
      case "CLASSROOM":
        const { classRooms } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(classRooms.data);
        break;
      case "GROUP":
        const { classes } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(classes.data);
        break;
      default:
        break;
    }
  };

  const onSubmit = async () => {
    const data = {
      type: "PREFERENCE",
      objType: formPreferenceType,
      ids: choosenPreferenceUnits.map((id) => ({ $oid: id })),
      slots: choosenTiles,
    };

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp);
  };

  return (
    <Stack gap={2}>
      <FormControl fullWidth>
        <InputLabel id="chooseTypeLabel">Wybierz typ preferencji</InputLabel>
        <Select
          labelId="chooseTypeLabel"
          id="chooseType"
          value={formPreferenceType}
          onChange={handleFormTemplateChange}
          label="Wybierz typ preferencji"
          MenuProps={formListConfig.MENU_PROPS}
        >
          {preferencesTypeList.map((type) => (
            <MenuItem key={`preferenceType-${type.id}`} value={type.type}>
              {type.polishtype}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedPreferenceItems && (
        <FormControl fullWidth>
          <InputLabel id="choosePreferenceLabel">
            Wybierz opcje z listy
          </InputLabel>
          {formPreferenceType !== "GROUP" ? (
            <Select
              labelId="choosePreferenceLabel"
              multiple
              multiline
              rows={6}
              columns={3}
              id="choosePreference"
              value={choosenPreferenceUnits}
              input={<OutlinedInput label="Wybierz opcje z listy" />}
              onChange={handleOptionsChange}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const preference = selectedPreferenceItems.find(
                      (p) => p._id.$oid === id
                    );
                    return preference ? preference.name : "";
                  })
                  .join(", ")
              }
              label="Wybierz opcje z listy"
              MenuProps={formListConfig.MENU_PROPS}
            >
              {selectedPreferenceItems.map((preference) => (
                <MenuItem
                  key={`preference-${preference._id.$oid}`}
                  value={preference._id.$oid}
                >
                  <Checkbox
                    checked={
                      choosenPreferenceUnits.indexOf(preference._id.$oid) > -1
                    }
                  />
                  {preference.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="choosePreferenceLabel"
              multiple
              multiline
              rows={6}
              columns={3}
              id="choosePreference"
              value={choosenPreferenceUnits}
              input={<OutlinedInput label="Wybierz opcje z listy" />}
              onChange={handleOptionsChange}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const group = selectedPreferenceItems
                      .flatMap((cl) => cl.groups)
                      .find((g) => g._id.$oid === id);
                    const className = selectedPreferenceItems.find((cl) =>
                      cl.groups.some((g) => g._id.$oid === id)
                    )?.name;
                    return group && className
                      ? `${className} - ${group.name}`
                      : "";
                  })
                  .join(", ")
              }
              label="Wybierz opcje z listy"
              MenuProps={formListConfig.MENU_PROPS}
            >
              {selectedPreferenceItems.map((preference) => [
                <ListSubheader key={`class-${preference._id.$oid}`}>
                  {preference.name}
                </ListSubheader>,
                ...preference.groups.map((group) => (
                  <MenuItem
                    key={`group-${group._id.$oid}`}
                    value={group._id.$oid}
                  >
                    <Checkbox
                      checked={
                        choosenPreferenceUnits.indexOf(group._id.$oid) > -1
                      }
                    />
                    {group.name}
                  </MenuItem>
                )),
              ])}
            </Select>
          )}
        </FormControl>
      )}
      {choosenPreferenceUnits.length > 0 && (
        <Stack>
          <Typography variant="h6">Wybrane opcje:</Typography>
          {formPreferenceType !== "GROUP" ? (
            <Stack>
              <Stack direction="row" flexWrap="wrap" gap={1} mt={2} mb={2}>
                {choosenPreferenceUnits.map((id) => {
                  const preference = selectedPreferenceItems.find(
                    (p) => p._id.$oid === id
                  );
                  return (
                    <Chip
                      key={`preference-chip-${id}`}
                      label={preference?.name}
                      color="success"
                      onDelete={() =>
                        setChoosenPreferenceUnits(
                          choosenPreferenceUnits.filter((p) => p !== id)
                        )
                      }
                    />
                  );
                })}
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" flexWrap="wrap" gap={1} mt={2} mb={2}>
              {choosenPreferenceUnits.map((id) => {
                const preference = selectedPreferenceItems
                  .flatMap((cl) => cl.groups)
                  .find((g) => g._id.$oid === id);
                const className = selectedPreferenceItems.find((cl) =>
                  cl.groups.some((g) => g._id.$oid === id)
                )?.name;
                return (
                  <Chip
                    key={`preference-chip-${id}`}
                    label={
                      preference && className
                        ? `${className} - ${preference.name}`
                        : ""
                    }
                    color="success"
                    onDelete={() =>
                      setChoosenPreferenceUnits(
                        choosenPreferenceUnits.filter((p) => p !== id)
                      )
                    }
                  />
                );
              })}
            </Stack>
          )}

          <SlotsNet
            days={days}
            slots={slots}
            handleTileChange={handleTileChange}
            tiles={choosenTiles}
          />
          <Button variant="contained" onClick={onSubmit}>
            Dodaj preferencje
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default PreferencesForm;
