import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const DialogAlert = ({
  isDialogOpen,
  handleDialogClose,
  cb,
  title,
  content,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>cofnij</Button>
          <Button onClick={cb} autoFocus color="error">
            usuń
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogAlert;
