import React, { useState, useEffect } from "react";

import {
  Box,
  TableRow,
  TableCell,
  Stack,
  Chip,
  Button,
  Divider,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";

import { observer } from "mobx-react";
import { toJS } from "mobx";

import ModalComponent from "../../../../Modal/ModalComponent";
import AddLesson from "../AddLesson";
import DialogAlert from "../../../../Alert/DialogAlert";
import styles from "./SingleRow.module.scss";

import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import SubjectRow from "./SubjectRow/SubjectRow";
import LessonSizeRow from "./LessonSizeRow/LessonSizeRow";
import LessonAmount from "./LessonAmount/LessonAmount";
import TeacherAmountRow from "./TeacherAmountRow/TeacherAmountRow";
import LabelRow from "./LabelRow/LabelRow";
import GroupsRow from "./GroupsRow/GroupsRow";
import AllowedClassroomsRow from "./AllowedClassroomsRow/AllowedClassroomsRow";

const availableSizes = [1, 2, 3, 4, 5];
const availableAmount = [1, 2, 3, 4, 5];

const SingleRow = observer(
  ({ classLesson, index, singleClass, lessonIdToCopy, setLessonIdToCopy }) => {
    const { subjects, teachers, labels } = SchoolInfoStore;
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
    const [isSubjectEditable, setIsSubjectEditable] = useState(false);
    const [subject, setSubject] = useState(classLesson.lessonType);
    const [teacher, setTeacher] = useState(classLesson.teacher);
    const [label, setLabel] = useState(classLesson.lessonLabel);

    const [isLabelEditable, setIsLabelEditable] = useState(false);

    const [isSizeEditable, setIsSizeEditable] = useState(false);
    const [isAmountEditable, setIsAmountEditable] = useState(false);

    const [size, setSize] = useState(classLesson.size);

    const [amount, setAmount] = useState(classLesson.amountPerWeek);

    const [isTeacherEditable, setIsTeacherEditable] = useState(false);

    const handleClose = () => setIsAddModalOpen(false);

    const deleteHandler = async (id) => {
      await SchoolInfoStore.deleteItem(id, "lessons", "lesson");
      setIsRemoveDialogOpen(false);
    };

    const handleRemoveGroup = async (groupId) => {
      classLesson.groupDetails = classLesson.groupDetails.filter(
        (group) => group._id.$oid !== groupId
      );

      await SchoolInfoStore.deleteGroupFromLesson(classLesson._id, groupId);
    };

    const handleRemoveClassroom = async (classroomId) => {
      await SchoolInfoStore.deleteClassromFromLesson(
        classLesson._id,
        classroomId
      );
    };

    //subject
    const handleSubjectChange = async (event) => {
      const selectedSubject = subjects.data.find(
        (sub) => sub._id.$oid === event.target.value
      );
      setSubject(toJS(selectedSubject));
      await SchoolInfoStore.updateLesson(classLesson._id, selectedSubject);

      setIsSubjectEditable(false);
    };

    const handleEdition = () => {
      setIsSubjectEditable(true);
    };

    //label
    const handleLabelChange = async (event) => {
      const selectedLabel = labels.data.find(
        (label) => label._id.$oid === event.target.value
      );
      setLabel(toJS(selectedLabel));

      await SchoolInfoStore.updateLabel(classLesson._id, selectedLabel);

      setIsLabelEditable(false);
    };

    const handleLabelEdition = () => {
      setIsLabelEditable(true);
    };

    //teacher
    const handleTeacherChange = async (event) => {
      const selectedTeacher = teachers.data.find(
        (teacher) => teacher._id.$oid === event.target.value
      );

      setTeacher(toJS(selectedTeacher));

      await SchoolInfoStore.updateTeacher(classLesson._id, selectedTeacher);

      setIsTeacherEditable(false);
    };

    const handleTeacherEdition = () => {
      setIsTeacherEditable(true);
    };

    //size
    const handleSizeChange = async (event) => {
      const newSize = event.target.value;
      setSize(newSize);

      await SchoolInfoStore.updateSize(classLesson._id, newSize);
      setIsSizeEditable(false);
    };

    const handleSizeEdition = () => {
      setIsSizeEditable(true);
    };

    //amount
    const handleAmountChange = async (event) => {
      const newAmount = event.target.value;
      setAmount(newAmount);

      await SchoolInfoStore.updateAmount(classLesson._id, newAmount);

      setIsAmountEditable(false);
    };
    const handleAmountEdition = () => {
      setIsAmountEditable(true);
    };

    //copy
    const handleCopyClassrooms = (lessonId) => {
      setLessonIdToCopy(lessonId);
    };
    const handlePasteClassrooms = (lessonId) => {
      if (!lessonIdToCopy) return;

      void SchoolInfoStore.copyClassrooms(lessonIdToCopy, lessonId);

      setLessonIdToCopy();
    };

    //select closing
    const handleEscapePress = (event) => {
      if (event.key === "Escape") {
        setIsSubjectEditable(false);
        setIsSizeEditable(false);
        setIsAmountEditable(false);
        setIsTeacherEditable(false);
        setIsLabelEditable(false);
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", handleEscapePress);

      return () => {
        window.removeEventListener("keydown", handleEscapePress);
      };
    }, []);

    return (
      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {isSubjectEditable ? (
            <SubjectRow
              subject={subject}
              handleSubjectChange={handleSubjectChange}
              subjects={subjects}
            />
          ) : (
            <Box onClick={handleEdition}>{classLesson.lessonType?.name} </Box>
          )}
        </TableCell>
        <TableCell>
          {isSizeEditable ? (
            <LessonSizeRow
              size={size}
              handleSizeChange={handleSizeChange}
              availableSizes={availableSizes}
            />
          ) : (
            <Box onClick={handleSizeEdition}>{classLesson.size} godz. </Box>
          )}
        </TableCell>
        <TableCell>
          {isAmountEditable ? (
            <LessonAmount
              amount={amount}
              handleAmountChange={handleAmountChange}
              availableAmount={availableAmount}
            />
          ) : (
            <Box onClick={handleAmountEdition}>{classLesson.amountPerWeek}</Box>
          )}
        </TableCell>
        <TableCell>
          {isTeacherEditable ? (
            <TeacherAmountRow
              teacher={teacher}
              handleTeacherChange={handleTeacherChange}
              teachers={teachers}
            />
          ) : (
            <Box onClick={handleTeacherEdition}>
              {classLesson.teacher?.name || (
                <Tooltip title="Proszę dopisać nauczyciela">
                  <WarningIcon color="error" />
                </Tooltip>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>
          {isLabelEditable ? (
            <LabelRow
              label={label}
              handleLabelChange={handleLabelChange}
              labels={labels}
            />
          ) : (
            <Box onClick={handleLabelEdition}>
              {classLesson.lessonLabel?.name ? (
                classLesson.lessonLabel?.name
              ) : (
                <p>Brak</p>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>
          <GroupsRow
            classLesson={classLesson}
            handleRemoveGroup={handleRemoveGroup}
            styles={styles}
          />
        </TableCell>
        <TableCell>
          <AllowedClassroomsRow
            classLesson={classLesson}
            setIsAddModalOpen={setIsAddModalOpen}
            styles={styles}
            handleRemoveClassroom={handleRemoveClassroom}
            lessonIdToCopy={lessonIdToCopy}
            handleCopyClassrooms={handleCopyClassrooms}
            handlePasteClassrooms={handlePasteClassrooms}
          />
        </TableCell>
        <TableCell>
          <Stack flexDirection="row">
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsAddModalOpen(true)}
            >
              <EditIcon />
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="text"
              color="error"
              onClick={() => setIsRemoveDialogOpen(true)}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        </TableCell>
        <ModalComponent isOpen={isAddModalOpen} handleClose={handleClose}>
          <AddLesson
            singleClass={singleClass}
            lessonData={classLesson}
            handleClose={handleClose}
          />
        </ModalComponent>
        <DialogAlert
          isDialogOpen={isRemoveDialogOpen}
          handleDialogClose={() => setIsRemoveDialogOpen(false)}
          cb={() => deleteHandler(classLesson._id)}
          content={"Czy na pewno chcesz usunąć lekcję?"}
          title={"Usuwanie lekcji"}
        />
      </TableRow>
    );
  }
);

export default SingleRow;
