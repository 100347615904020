import { Container, Typography, Box } from "@mui/material";

const License = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Umowa Licencyjna
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Przedmiot Umowy
        </Typography>
        <Typography paragraph>
          ● Przedmiotem umowy jest udzielenie przez Licencjodawcę Licencjobiorcy
          niewyłącznej, nieprzenoszalnej licencji na korzystanie z aplikacji
          SaaS do generowania planów lekcji na okres 30 dni.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Zakres Licencji
        </Typography>
        <Typography paragraph>
          ● Licencjobiorca ma prawo do korzystania z aplikacji zgodnie z jej
          przeznaczeniem i warunkami określonymi w Regulaminie Usługi.
          <br />● Licencja obejmuje dostęp do wersji darmowej oraz płatnej,
          zgodnie z wykupionym pakietem.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Warunki Płatności
        </Typography>
        <Typography paragraph>
          ● Licencjobiorca zobowiązuje się do uiszczenia opłaty licencyjnej
          zgodnie z cennikiem opublikowanym na stronie Licencjodawcy.
          <br />● Opłaty nie podlegają zwrotowi po wygenerowaniu płatnych
          planów.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Okres Obowiązywania Umowy
        </Typography>
        <Typography paragraph>
          ● Umowa obowiązuje od momentu akceptacji warunków przez Licencjobiorcę
          i rejestracji w systemie.
          <br />● Umowa zostaje zawarta na czas 30 dni.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Prawa i Obowiązki Stron
        </Typography>
        <Typography paragraph>
          ● Licencjodawca:
          <br />
          ○ Zapewnia dostęp do aplikacji i wsparcie techniczne.
          <br />
          ○ Zastrzega sobie prawo do aktualizacji i modyfikacji aplikacji.
          <br />
          ● Licencjobiorca:
          <br />
          ○ Korzysta z aplikacji zgodnie z jej przeznaczeniem.
          <br />○ Nie udostępnia dostępu do aplikacji osobom trzecim.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Ograniczenie Odpowiedzialności
        </Typography>
        <Typography paragraph>
          ● Licencjodawca nie odpowiada za szkody wynikłe z korzystania z
          aplikacji, w tym za utratę danych lub przerwy w dostępie.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Ochrona Danych Osobowych
        </Typography>
        <Typography paragraph>
          ● Dane osobowe Licencjobiorcy są przetwarzane zgodnie z Polityką
          Prywatności Licencjodawcy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Rozwiązanie Umowy
        </Typography>
        <Typography paragraph>
          ● Umowa może zostać rozwiązana przez każdą ze stron z 30-dniowym
          okresem wypowiedzenia.
          <br />● Licencjodawca może wypowiedzieć umowę ze skutkiem
          natychmiastowym w przypadku naruszenia przez Licencjobiorcę warunków
          umowy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Postanowienia Końcowe
        </Typography>
        <Typography paragraph>
          ● Wszelkie zmiany umowy wymagają formy pisemnej.
          <br />
          ● W sprawach nieuregulowanych umową mają zastosowanie przepisy Kodeksu
          Cywilnego.
          <br />● Spory wynikłe z umowy będą rozstrzygane przez sąd właściwy dla
          siedziby Licencjodawcy.
        </Typography>
      </Box>
    </Container>
  );
};

export default License;
