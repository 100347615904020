import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Stack,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import RefreshIcon from "@mui/icons-material/Refresh";
import SchoolInfoStore from "../../../../mobx/SchoolInfoStore";
import { observer } from "mobx-react";

const AddClass = observer(({ classesLength }) => {
  const [className, setClassName] = useState("");
  const [groups, setGroups] = useState("");
  const [classNameError, setClassNameError] = useState("");
  const [groupsError, setGroupsError] = useState("");

  const handleAdd = async () => {
    setClassNameError("");
    setGroupsError("");

    if (className.trim().length === 0) {
      setClassNameError("Nazwa klasy nie może być pusta");
      return;
    }

    const classResponse = await SchoolInfoStore.addItem(
      { name: className },
      "classes",
      "klasa",
      "studentClass"
    );
    if (classResponse?.error) {
      setClassNameError(classResponse.errorMessage);
      return;
    }

    const classId = classResponse._id;
    let groupsToAdd = [];

    if (groups.trim().length > 0) {
      groupsToAdd = groups
        .split(",")
        .map((group) => group.trim())
        .filter((group) => group.length > 0);
    }

    if (groupsToAdd.length === 0) {
      groupsToAdd.push(className);
    }

    if (groupsToAdd.length > 0) {
      const groupsToAddObjects = groupsToAdd.map((group) => ({
        name: group,
        student_class_id: classId,
      }));
      const groupsResponse = await SchoolInfoStore.addGroupsToClass(
        groupsToAddObjects
      );
      if (groupsResponse?.error) {
        setGroupsError(groupsResponse.errorMessage);
        return;
      }
    }

    setClassName("");
    setGroups("");

    window.location.reload();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAdd();
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {classesLength + 1}.
      </TableCell>
      <TableCell component="th" scope="row">
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          <HelpIcon sx={{ color: "grey" }} />
          <TextField
            value={className}
            id="new_class"
            label="Dodaj klasę"
            onChange={(e) => setClassName(e.target.value)}
            onKeyDown={handleKeyPress}
            error={!!classNameError}
            helperText={classNameError}
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          <Tooltip title="Aby dodać kilka grup, dodaj je po przecinku np. (Grupa A, Grupa B)">
            <HelpIcon sx={{ color: "grey" }} />
          </Tooltip>
          <TextField
            value={groups}
            id="new_groups"
            label="Dodaj grupy"
            onChange={(e) => setGroups(e.target.value)}
            onKeyDown={handleKeyPress} // Użyj onKeyDown zamiast onKeyPress
            error={!!groupsError}
            helperText={groupsError}
          />
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Stack gap={1} flexDirection={"row"} justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              setClassName("");
              setGroups("");
              setClassNameError("");
              setGroupsError("");
            }}
            sx={{ color: "grey" }}
          >
            <RefreshIcon />
          </Button>
          <Divider flexItem orientation="vertical" />
          <Button onClick={handleAdd}>
            <AddIcon />
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
});

export default AddClass;
