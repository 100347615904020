import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { generateCorrectForm, checkPlan } from "../requirementsConfig";
import AuthStore from "../../../../mobx/AuthStore";
import { toJS } from "mobx";

const FormTemplate = ({ isEdit = false }) => {
  const [formTemplateType, setFormTemplateType] = useState("");
  const { plan } = toJS(AuthStore.user);
  const handleFormTemplateChange = (e) => {
    setFormTemplateType(e.target.value);
  };
  console.log(plan);

  return (
    <Box width={550}>
      <Stack display={"flex"} justifyContent={"center"}>
        <Typography variant="h4" textAlign={"center"}>
          {isEdit ? "Edytuj wymaganie" : "Dodaj wymaganie"}
        </Typography>
      </Stack>
      <Stack mt={2}>
        <FormControl fullWidth>
          <InputLabel id="chooseTypeLabel">Wybierz typ wymagania</InputLabel>
          <Select
            labelId="chooseTypeLabel"
            id="chooseType"
            value={formTemplateType}
            onChange={handleFormTemplateChange}
            label="Wybierz typ wymagania"
          >
            {checkPlan("FREE")}
          </Select>
        </FormControl>
      </Stack>
      <Stack mt={2}>{generateCorrectForm(formTemplateType)}</Stack>
    </Box>
  );
};

export default FormTemplate;
