import React, { useState } from "react";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import {
  Stack,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  ListSubheader,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  edgeTypes,
  formListConfig,
  blockingLessonsTypeList,
} from "../../requirementsConfig";
import { toJS } from "mobx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const EdgeLessonForm = () => {
  const { subjects, classes, labels, lessons } = toJS(SchoolInfoStore);
  const [selectedBlockingType, setSelectedBlockingType] = useState("");

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedEdgeType, setSelectedEdgeType] = useState("");
  const handleSelectEdgeType = (e) => {
    setSelectedEdgeType(e.target.value);
  };

  const [selectedGroups, setSelectedGroups] = useState([]);
  const handleGroupsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleItemsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedItems(typeof value === "string" ? value.split(",") : value);
  };

  const handleLessonChange = (lesson) => {
    if (selectedItems.some((l) => l._id.$oid === lesson._id.$oid)) {
      setSelectedItems(selectedItems.filter((l) => l._id.$oid !== lesson._id.$oid));
      return;
    }
    setSelectedItems([...selectedItems, lesson]);
  };

  const handleItemsSelect = (e) => {
    setItems([]);
    setSelectedItems([]);
    setSelectedGroups([]);
    const item = e.target.value;
    setSelectedBlockingType(item);

    switch (item) {
      case "LESSON_TYPE":
        setItems(subjects?.data);
        break;
      case "LESSON_LABEL":
        setItems(labels?.data);
        break;
      case "LESSON_ID":
        setItems(lessons?.data);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const data = {
      type: "EDGE_LESSON",
      attType: selectedBlockingType,
      edgeType: selectedEdgeType,
      groupsIds: selectedGroups.map((g) => ({ $oid: g })),
      ids: selectedBlockingType === "LESSON_ID" ? selectedItems.map((l) => l._id) : selectedItems.map((l) => ({ $oid: l })),
    };
    
    const rsp = await RequirementsStore.postRequirement(data);

  };

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Typography variant="h6">Wybierz typ krańców:</Typography>
        <Stack>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="edgeTypeLabel">Typ krańców</InputLabel>
            <Select
              labelId="edgeTypeLabel"
              id="edgeType"
              label="Typ krańców"
              fullWidth
              value={selectedEdgeType}
              onChange={handleSelectEdgeType}
            >
              {edgeTypes.map((type) => (
                <MenuItem key={`edgeType-${type.id}`} value={type.type}>
                  {type.polishtype}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {selectedEdgeType.length > 0 && (
          <Stack gap={1}>
            <Typography variant="h6">Wyierz typ zajęć:</Typography>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="blockingTypeLabel">Typ zajęć</InputLabel>
              <Select
                labelId="blockingTypeLabel"
                id="blockingType"
                label="Typ zajęć"
                value={selectedBlockingType}
                onChange={handleItemsSelect}
              >
                {blockingLessonsTypeList.map((type) => (
                  <MenuItem key={`blockingType-${type.id}`} value={type.type}>
                    {type.polishtype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
        {selectedBlockingType.length > 0 && (
            <Stack gap={1}>
              <Typography variant="h6">Wybierz grupy:</Typography>
              <FormControl>
                <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
                <Select
                  labelId="chooseGroupsLabel"
                  id="chooseGroups"
                  multiple
                  value={selectedGroups}
                  onChange={handleGroupsChange}
                  label="Wybierz grupy"
                  MenuProps={formListConfig.MENU_PROPS}
                  renderValue={(selected) =>
                    selected
                      .map((id) => {
                        const group = classes.data
                          .flatMap((cl) => cl.groups)
                          .find((g) => g._id.$oid === id);
                        const className = classes.data.find((cl) =>
                          cl.groups.some((g) => g._id.$oid === id)
                        )?.name;
                        return group && className
                          ? `${className} - ${group.name}`
                          : "";
                      })
                      .join(", ")
                  }
                >
                  {classes.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((cl) => [
                      <ListSubheader key={`class-${cl._id.$oid}`}>
                        {cl.name}
                      </ListSubheader>,
                      ...cl.groups.map((group) => (
                        <MenuItem
                          key={`group-${group._id.$oid}`}
                          value={group._id.$oid}
                        >
                          <Checkbox
                            checked={
                              selectedGroups.indexOf(group._id.$oid) > -1
                            }
                          />
                          {group.name}
                        </MenuItem>
                      )),
                    ])}
                </Select>
              </FormControl>
              {selectedGroups.length > 0 && (
                  <Stack>
                    <Typography variant="h6">Wybrane grupy:</Typography>
                    <Stack
                      gap={1}
                      display={"flex"}
                      flexDirection={"row"}
                      flexWrap={"wrap"}
                    >
                      {selectedGroups.map((id) => {
                        const group = classes.data
                          .flatMap((cl) => cl.groups)
                          .find((g) => g._id.$oid === id);
                        const className = classes.data.find((cl) =>
                          cl.groups.some((g) => g._id.$oid === id)
                        )?.name;
                        return (
                          <Chip
                            key={`selected-group-${id}`}
                            label={
                              group && className
                                ? `${className} - ${group.name}`
                                : ""
                            }
                            onDelete={() => {
                              setSelectedGroups((prev) =>
                                prev.filter((groupId) => groupId !== id)
                              );
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                )}
            </Stack>
          )}
        {selectedGroups.length > 0 && selectedBlockingType !== "LESSON_ID" && (
          <Stack mt={2}>
            <Stack gap={1}>
              <Typography variant="h6">Wybierz przedmioty:</Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="chooseSubjectsLabel">Wybierz opcje</InputLabel>
                <Select
                  labelId="chooseSubjectsLabel"
                  id="chooseSubjects"
                  multiple
                  value={selectedItems}
                  onChange={handleItemsChange}
                  label="Wybierz opcje"
                  MenuProps={formListConfig.MENU_PROPS}
                  renderValue={(selected) =>
                    selected
                      .map((id) => {
                        const subject = items.find((s) => s._id.$oid === id);
                        return subject ? subject.name : "";
                      })
                      .join(", ")
                  }
                >
                  {items.map((subject) => (
                    <MenuItem
                      key={`subject-${subject._id.$oid}`}
                      value={subject._id.$oid}
                    >
                      <Checkbox
                        checked={selectedItems.indexOf(subject._id.$oid) > -1}
                      />
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack mt={2} mb={2}>
                <Typography variant="h6">Wybrane przedmioty:</Typography>
                <Stack
                  gap={1}
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                >
                  {selectedItems.map((id) => {
                    const subject = items.find((s) => s._id.$oid === id);
                    return (
                      <Chip
                        key={`selected-subject-${id}`}
                        label={subject ? subject.name : ""}
                        onDelete={() => {
                          setSelectedItems((prev) =>
                            prev.filter((subjectId) => subjectId !== id)
                          );
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Button variant="contained" onClick={handleSubmit}>Dodaj zajęcia na krawędziach</Button>
            </Stack>
          </Stack>
        )}
        {selectedBlockingType === "LESSON_ID" && (
          <Stack gap={1}>
            <Typography variant="h6">Wybierz lekcje:</Typography>
            {classes?.data?.length === 0
              ? "Brak klas"
              : classes.data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((cl) => {
                    const classLessons = items.filter(
                      (lesson) => lesson.student_class_id.$oid === cl._id.$oid
                    );

                    return (
                      <Accordion key={`class-accordion-${cl._id.$oid}`}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                          <Typography>{cl.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack gap={2}>
                            {classLessons.length === 0
                              ? "Brak lekcji"
                              : classLessons
                                  .sort((a, b) =>
                                    a.lessonType.name.localeCompare(
                                      b.lessonType.name
                                    )
                                  )
                                  .map((lesson) => (
                                    <Stack
                                      gap={2}
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                    >
                                      <Typography>
                                        {lesson.lessonType.name}
                                      </Typography>
                                      <Stack
                                        gap={1}
                                        display={"flex"}
                                        flexDirection={"row"}
                                      >
                                        {lesson?.groupDetails?.map((group) => (
                                          <Chip label={group?.name} />
                                        ))}
                                      </Stack>
                                      <Stack
                                        gap={1}
                                        display={"flex"}
                                        flexDirection={"row"}
                                      >
                                        {lesson?.allowedClassroomsDetails?.map(
                                          (label, index) =>
                                            index < 2 && (
                                              <Chip
                                                label={label?.name}
                                                color="secondary"
                                              />
                                            )
                                        )}
                                        <Button
                                          onClick={() =>
                                            handleLessonChange({
                                              ...lesson,
                                              className: cl.name,
                                            })
                                          }
                                        >
                                          +
                                        </Button>
                                      </Stack>
                                    </Stack>
                                  ))}
                                  {}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  {selectedItems.length > 0 && (
                    <Stack mt={2}>
                      <Typography variant="h6">Wybrane lekcje:</Typography>
                      <Stack
                        gap={1}
                        display={"flex"}
                        flexDirection={"row"}
                        flexWrap={"wrap"}
                        mb={2}
                      >
                        {selectedItems.map((lesson) => (
                          <Chip
                            key={`selected-lesson-${lesson._id.$oid}`}
                            label={`${lesson.lessonType.name} - ${lesson.className}`}
                            onDelete={() => {
                              setSelectedItems((prev) =>
                                prev.filter((l) => l._id.$oid !== lesson._id.$oid)
                              );
                            }}
                          />
                        ))}
                      </Stack>
                      <Button variant="contained" onClick={handleSubmit}>Dodaj zajęcia na krawędziach</Button>
                      </Stack>
                  )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EdgeLessonForm;
