import React from "react";
import SinglePlan from "./SinglePlan/SinglePlan";
import { Divider } from "@mui/material";
import styles from "./Plans.module.scss";

const Plans = ({ plans }) => {
  const { data } = plans;

  const sortedData = data.sort(
    (a, b) => new Date(b.creation_date) - new Date(a.creation_date)
  );

  return (
    <div className={styles.plans}>
      {sortedData.map((plan, index) => (
        <React.Fragment key={plan.id}>
          <SinglePlan plan={plan} index={sortedData.length - index} />
          {index !== sortedData.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Plans;
