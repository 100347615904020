export const steps = [
  {
    step: "#1: Konfiguracja szkoły",
    description:
      "Rozpocznij od wprowadzenia podstawowych informacji o swojej szkole. Wymagamy minimum informacji i udostępniamy szereg mechanizmów przyspieszających ten proces.",
  },
  {
    step: "#2: Wymagania planu",
    description:
      "Ustal szczegółowe wymagania dotyczące planu lekcyjnego, takie jak rozkład lekcji, preferencje czasowe oraz ograniczenia dotyczące sal i nauczycieli. Nasze zaawansowane opcje konfiguracji pozwalają na precyzyjne dopasowanie planu do potrzeb Twojej szkoły.",
  },
  {
    step: "#3: Generacja planu",
    description:
      "Wygeneruj plan dla uczniów, nauczycieli i sal lekcyjnych jednym przyciskiem. Nasze inteligentne algorytmy uwzględniają wszystkie wprowadzone wymagania i preferencje, tworząc harmonogram, który maksymalizuje efektywność i minimalizuje konflikty. Wszystko dzieje się na naszych szybkich komputerach.",
  },
  {
    step: "#4: Podziel się opinią!",
    description:
      "Twoja opinia jest dla nas niezwykle cenna! Powiedz nam, co Ci się podoba, co moglibyśmy ulepszyć i jakie dodatkowe funkcje chciałbyś zobaczyć. Dzięki Twoim sugestiom możemy jeszcze lepiej dopasować aplikację do Twoich potrzeb i oczekiwań.",
  },
];
