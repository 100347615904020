import React from 'react';
import { Select, MenuItem } from '@mui/material';

const LessonSizeRow = ({size, handleSizeChange, availableSizes}) => {
  return (
    <Select
    labelId="size"
    id="size"
    value={size}
    label="Rozmiar lekcji"
    onChange={handleSizeChange}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 48 * 4.5 + 8,
          width: 250,
        },
      },
    }}
  >
    {availableSizes.map((size) => (
      <MenuItem key={size} value={size}>
        {size} godz.
      </MenuItem>
    ))}
  </Select>
  )
}

export default LessonSizeRow