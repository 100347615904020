import "./App.scss";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#76abae',
      },
      secondary: {
        main: '#091540'
      },
      warning: {
        main: '#ed6c02'
      }
      
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </HelmetProvider>
      </ThemeProvider>
      
    </div>
  );
}

export default App;
