import { observable, action, toJS } from "mobx";
import SchoolInfoStore from "./SchoolInfoStore";
import DataService from "../services/DataService";

class CopyLessonsStore {
  @observable copiedLessons = [];

  @action
  addLesson(lesson) {
    this.lessons.push(lesson);
  }

  @action
  removeLesson(lesson) {
    this.lessons = this.lessons.filter((l) => l !== lesson);
  }

  async copyLessonsFromClass(schoolClass, toClass, selectedLessonsToCopy) {
    const { schoolPlanId } = toJS(SchoolInfoStore);
    const data = {
      lessons_to_copy: selectedLessonsToCopy.map((lesson) => lesson._id),
      to_class: {$oid: toClass},
    }

    await DataService.copyLessons(data);
    const newLessons = await DataService.getLessons('lessons', schoolPlanId);
    SchoolInfoStore.setLessonsAfterCopying(newLessons.data);
  }
}

export default new CopyLessonsStore();
