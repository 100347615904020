import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import PlansStore from "../../../../../mobx/PlansStore";
import { toJS } from "mobx";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LessonPlan from "./LessonPlan/LessonPlan";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { createRoot } from "react-dom/client";
import LoadingBar from "../../../../LoadingBar/LoadingBar";

const Timetable = () => {
  const { planId } = useParams();
  const plan = toJS(PlansStore.getPlanById(planId));
  const { best_plan: preparedPlans, best_detailed_evaluation: evaluations } =
    plan;
  const { num_of_days: days, num_of_slots_per_day: slots } = preparedPlans;
  const classPlans = preparedPlans?.classes_plans?.sort((a, b) =>
    a.class_name > b.class_name ? 1 : -1
  );
  const teacherPlans = preparedPlans?.teacher_plans?.sort((a, b) =>
    a.teacher_name > b.teacher_name ? 1 : -1
  );
  const classroomPlans = preparedPlans?.classroom_plans?.sort((a, b) =>
    a.classroom_name > b.classroom_name ? 1 : -1
  );
  const [pickedPlan, setPickedPlan] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [planLoadingRender, setPlanLoadingRender] = useState(false);

  const handlePlanPick = (pickedPlan, planType) => {
    setPickedPlan(pickedPlan);
    setPlanType(planType);
  };

  const handlePlanExport = async (type) => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    let plans = [];

    switch (type) {
      case "class":
        plans = classPlans;
        break;
      case "teacher":
        plans = teacherPlans;
        break;
      case "classroom":
        plans = classroomPlans;
        break;
      default:
        console.log("Exporting PDF for unknown type");
        return;
    }
    setPlanLoadingRender(true);
    for (let i = 0; i < plans.length; i++) {
      const printContainer = document.createElement("div");
      printContainer.style.position = "absolute";
      printContainer.style.top = "-9999px";
      document.body.appendChild(printContainer);

      const root = createRoot(printContainer);

      root.render(
        <LessonPlan
          choosenPlan={plans[i]}
          slots={slots}
          days={days}
          planType={type}
          isVisible={plan.visible}
          isRendering={true}
        />
      );

      await new Promise((resolve) => setTimeout(resolve, 100));

      const input = printContainer.querySelector("#lessonPlan");
      const canvas = await html2canvas(input, {
        scale: 1.5,
        scrollX: 0,
        scrollY: 0,
        width: input.scrollWidth,
        height: input.scrollHeight,
      });

      const imgData = canvas.toDataURL("image/png");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const scaleFactor = Math.min(
        pageWidth / imgWidth,
        pageHeight / imgHeight
      );

      const adjustedWidth = imgWidth * scaleFactor;
      const adjustedHeight = imgHeight * scaleFactor;

      if (i > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "PNG", 0, 0, adjustedWidth, adjustedHeight);
      document.body.removeChild(printContainer);
    }

    setPlanLoadingRender(false);
    pdf.save(`${type.toLowerCase()}-plans.pdf`);
  };

  if (!plan.visible) {
    return (
      <Stack>
        <Typography>
          <Button>
            <NavLink to={-1}>Powrót</NavLink>
          </Button>
        </Typography>
        <Typography variant="h3">
          PLAN NIEDOSTĘPNY W WERSJI DARMOWEJ!
        </Typography>
        <Typography variant="h5" textAlign={"center"}>
          <NavLink to="/purchase">
            Kliknij tutaj aby zakupić pełną wersję aplikacji
          </NavLink>
        </Typography>
      </Stack>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {pickedPlan !== null ? pickedPlan[`${planType}_name`] : "Wybór planów"}
      </Typography>
      {pickedPlan === null ? (
        <Box
          component="div"
          display={"flex"}
          flexDirection={"row"}
          alignItems={"space-between"}
          justifyContent={"space-evenly"}
        >
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla klas
            </Typography>
            {classPlans && classPlans.length > 0 ? (
              planLoadingRender ? (
                <Button variant={"contained"} disabled={true}>
                  <LoadingBar />
                </Button>
              ) : (
                <Button
                  variant={"contained"}
                  onClick={() => handlePlanExport("class")}
                >
                  Pobierz PDF dla klas
                </Button>
              )
            ) : null}
            <Box display='flex' justifyContent={'center'} flexDirection={'column'} mt={1}>
              {classPlans ? (
                classPlans?.map((singleClass) => (
                  <Chip
                    key={singleClass.class_name}
                    style={{ margin: "3px 0", cursor: "pointer" }}
                    onClick={() => handlePlanPick(singleClass, "class")}
                    label={singleClass.class_name}
                  >
                    {singleClass.class_name}
                  </Chip>
                ))
              ) : (
                <h3 style={{ margin: "3px 0" }}>Brak planów dla klas</h3>
              )}
            </Box>
          
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla nauczycieli
            </Typography>
            {teacherPlans && teacherPlans.length > 0 ? (
              planLoadingRender ? (
                <Button variant={"contained"} disabled={true}>
                  <LoadingBar />
                </Button>
              ) : (
                <Button
                  variant={"contained"}
                  onClick={() => handlePlanExport("teacher")}
                >
                  Pobierz PDF dla nauczycieli
                </Button>
              )
            ) : null}
            <Box display='flex' justifyContent={'center'} flexDirection={'column'} mt={1}>
            {teacherPlans ? (
              teacherPlans?.map((singleTeacher) => (
                <Chip
                  key={singleTeacher.teacher_name}
                  style={{ margin: "3px 0", cursor: "pointer" }}
                  onClick={() => handlePlanPick(singleTeacher, "teacher")}
                  label={singleTeacher.teacher_name}
                  
                >
                </Chip>
              ))
            ) : (
              <h3 style={{ margin: "3px 0" }}>Brak planów dla nauczycieli</h3>
            )}
            </Box>

          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla sal lekcyjnych
            </Typography>
            {classroomPlans && classroomPlans.length > 0 ? (
              planLoadingRender ? (
                <Button variant={"contained"} disabled={true}>
                  <LoadingBar />
                </Button>
              ) : (
                <Button
                  variant={"contained"}
                  onClick={() => handlePlanExport("classroom")}
                >
                  Pobierz PDF dla sal lekcyjnych
                </Button>
              )
            ) : null}
            <Box display='flex' justifyContent={'center'} flexDirection={'column'} mt={1}>
            {classroomPlans ? (
              classroomPlans?.map((singleClassroom) => (
                <Chip
                  key={singleClassroom.classroom_name}
                  style={{ margin: "3px 0", cursor: "pointer" }}
                  onClick={() => handlePlanPick(singleClassroom, "classroom")}
                  label={singleClassroom.classroom_name}
                >
                </Chip>
              ))
            ) : (
              <h3 style={{ margin: "3px 0" }}>
                Brak planów dla sal lekcyjnych
              </h3>
            )}
            </Box>
           
          </Stack>
        </Box>
      ) : (
        <Box component="div" width={"100%"}>
          <Box
            component="div"
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{ marginBottom: "8px" }}
          >
            <Button onClick={() => handlePlanPick(null, null)}>Powrót</Button>
            <Tooltip title="Aby w łatwy sposób przesunąć plan w prawo lub w lewo, użyj kółka myszy, przytrzymując klawisz shift ">
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <LessonPlan
            choosenPlan={pickedPlan}
            slots={slots}
            days={days}
            planType={planType}
            isVisible={plan.visible}
          />
        </Box>
      )}
    </div>
  );
};

export default Timetable;
