import React from "react";
import styles from "./Banner.module.scss";
import { Button, Container } from "@mui/material";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";

const Banner = () => {
  const { isUserLogged } = AuthStore;
  return (
    <section id="banner" className={styles.banner}>
      <motion.div
        className={styles.image}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
      <div className={styles.shape}></div>

      </motion.div>
      <motion.div
        className={styles.content}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
            <Container>

        <div className={styles.text}>
          <motion.h2>
            EasySchedule
          </motion.h2>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Kreator <br/>planów lekcji
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.9 }}
          >
            Szybko i łatwo twórz idealne plany lekcji dzięki naszemu intuicyjnemu narzędziu<br/> do projektowania harmonogramów zajęć.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1.2 }}
          >
            <NavLink to={isUserLogged ? "/profile" : "/login"}>
              <Button
                variant="contained"
                color="primary"
                sx={{ padding: "15px 25px" }}
              >
                Wypróbuj za darmo
              </Button>
            </NavLink>
          </motion.div>
        </div>
        </Container>

      </motion.div>
    </section>

  );
};

export default Banner;
