import React from "react";
import { Container } from "@mui/material";
import styles from "./Functionalities.module.scss";
import { functions } from "./functionalitiesData";
import FunctionalCategory from "./FunctionalitiesCategory";

const Functionalities = () => {
  return (
    <Container id="functionalities">
      <div className={styles.functionalitiesWrapper}>
        <h2 className={styles.title}>Funkcje naszej aplikacji</h2>
        <div className={styles.functionalities}>
          <div className={styles.container}>
            {functions.map((category, index) => (
              <FunctionalCategory
                key={index}
                category={category}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Functionalities;
