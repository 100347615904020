import React, { useState } from "react";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Button,
  ListSubheader,
  Slider,
} from "@mui/material";
import { toJS } from "mobx";
import { formListConfig } from "../../requirementsConfig";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const LessonStartForm = () => {
  const { classes } = toJS(SchoolInfoStore);
  const { numOfDays: days, numOfSlotsPerDay: slots } = toJS(
    SchoolInfoStore?.schoolPlanConfig?.data?.[0]
  );

  const hoursArrayGenerator = () => {
    return Array.from({ length: days }, (_, i) => i + 1).reduce((acc, day) => {
      acc[day] = 1;
      return acc;
    }, {});
  };

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [lessonsStarts, setLessonsStarts] = useState(hoursArrayGenerator());

  const handleSelectedLessonStarts = (e, day) => {
    const newSlots = e.target.value;
    setLessonsStarts({
      ...lessonsStarts,
      [day]: newSlots,
    });
  };

  const handleSubmit = async () => {
    const data = {
      type: "LESSON_START",
      groupsIds: selectedGroups.map((g) => ({ $oid: g })),
      lessonsStarts: Object.entries(lessonsStarts).map(([day, start]) => (start)),
    };

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp)

  };

  const handleGroupsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h6">Wybierz grupy:</Typography>
        <FormControl fullWidth>
          <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
          <Select
            labelId="chooseGroupsLabel"
            id="chooseGroups"
            multiple
            value={selectedGroups}
            input={<OutlinedInput label="Wybierz grupy" />}
            onChange={handleGroupsChange}
            label="Wybierz grupy"
            MenuProps={formListConfig.MENU_PROPS}
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const group = classes.data
                    .flatMap((cl) => cl.groups)
                    .find((g) => g._id.$oid === id);
                  const className = classes.data.find((cl) =>
                    cl.groups.some((g) => g._id.$oid === id)
                  )?.name;
                  return group && className
                    ? `${className} - ${group.name}`
                    : "";
                })
                .join(", ")
            }
          >
            {classes.data.map((cl) => [
              <ListSubheader key={`class-${cl._id.$oid}`}>
                {cl.name}
              </ListSubheader>,
              ...cl.groups.map((group) => (
                <MenuItem
                  key={`group-${group._id.$oid}`}
                  value={group._id.$oid}
                >
                  <Checkbox
                    checked={selectedGroups.indexOf(group._id.$oid) > -1}
                  />
                  {group.name}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>
      </Stack>
      <Stack>
        {selectedGroups.length > 0 && (
          <>
            <Typography variant="h6">Wybrane grupy:</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} mb={1}>
              {selectedGroups.map((id) => {
                const group = classes.data
                  .flatMap((cl) => cl.groups)
                  .find((g) => g._id.$oid === id);
                const className = classes.data.find((cl) =>
                  cl.groups.some((g) => g._id.$oid === id)
                )?.name;
                return (
                  <Chip
                    key={`group-${group._id.$oid}`}
                    label={`${className} - ${group?.name}`}
                    onDelete={() => {
                      setSelectedGroups((groups) =>
                        groups.filter((group) => group !== id)
                      );
                    }}
                  />
                );
              })}
            </Stack>
            <Stack gap={2}>
              <Typography variant="h6">
                Ustaw początkowe godziny dla podanych dni:{" "}
              </Typography>
              <Stack
                display="flex"
                flexDirection={"row"}
                justifyContent={"space-between"}
                maxWidth={550}
                flexWrap={"wrap"}
                gap={2}
                mb={2}
              >
                {Array.from({ length: days }, (_, i) => i + 1).map((day) => (
                  <Stack key={`day-${day}`} gap={1} width={200}>
                    <Typography variant="h6">Dzień {day}</Typography>
                    <Stack>
                      <FormControl>
                        <Stack>
                          <Slider
                            value={lessonsStarts[day] || 1}
                            onChange={(e) => handleSelectedLessonStarts(e, day)}
                            step={1}
                            marks
                            min={1}
                            max={slots}
                            valueLabelDisplay="auto"
                          />
                        </Stack>
                      </FormControl>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Button variant="contained" onClick={handleSubmit}>Zapisz</Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default LessonStartForm;
