import {
  CalendarPlus,
  Sliders,
  Settings,
  Cloud,
  BarChart,
  History,
} from "lucide-react";

export const functions = [
  {
    category: "Automatyczne Generowanie Planów",
    icon: <CalendarPlus size={50} strokeWidth={1} />,
    functions: [
      "Nasza aplikacja automatycznie tworzy optymalne plany lekcji, oszczędzając czas i eliminując błędy.",
      "Wprowadź podstawowe dane i pozwól, że zajmiemy się resztą.",
    ],
  },
  {
    category: "Intuicyjna Konfiguracja i Personalizacja",
    icon: <Sliders size={50} strokeWidth={1} />,
    functions: [
      "Łatwe dostosowywanie harmonogramu do specyficznych potrzeb szkoły, nauczycieli i uczniów.",
      "Dodaj sale, przedmioty, nauczycieli i klasy zaledwie w kilku krokach.",
    ],
  },
  {
    category: "Zaawansowane Opcje Zarządzania",
    icon: <Settings size={50} strokeWidth={1} />,
    functions: [
      "Kontroluj rozkład lekcji, preferencje czasowe, blokowanie jednoczesnych zajęć i inne szczegóły.",
      "Nasze zaawansowane funkcje zapewniają pełną elastyczność w tworzeniu planów.",
    ],
  },
  {
    category: "Przechowywanie Danych w Chmurze",
    icon: <Cloud size={50} strokeWidth={1} />,
    functions: [
      "Twoje dane są bezpiecznie przechowywane w chmurze, co zapewnia ich łatwy dostęp i ochronę przed utratą.",
      "Dzięki chmurze, możesz zarządzać swoimi planami lekcji z dowolnego miejsca i urządzenia.",
    ],
  },
  {
    category: "Analiza jakości planów",
    icon: <BarChart size={50} strokeWidth={1} />,
    functions: [
      "Wbudowane wskaźniki jakości, które pozwalają na ocenę wygenerowanych planów przed zatwierdzeniem.",
    ],
  },
  {
    category: "Historia i wersjonowanie",
    icon: <History size={50} strokeWidth={1} />,
    functions: [
      "Możliwość zapisywania i przywracania poprzednich wersji harmonogramów.",
    ],
  },
];
