import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolInfoStore from "../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";

const findClassesForGroups = (groups, flag) => {
  const { classes } = toJS(SchoolInfoStore);
  const groupsArray = [];

  if (flag === "GROUP") {
    classes?.data?.forEach((cl) => {
      cl?.groups?.forEach((gr) => {
        groups?.forEach((g) => {
          if (g._id.$oid === gr._id.$oid) {
            groupsArray.push({ name: `${cl.name} - ${gr.name}` });
          }
        });
      });
    });
    return groupsArray;
  }

  return groups;
};

const possibleRequirements = {
  PREFERENCE: (requirement) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          {translateRequirementType(requirement.type)}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            with={"100%"}
            gap={1}
          >
            <Stack width={"30%"}>
              <Chip label={translateRequirementType(requirement.type)} color="success" />

            </Stack>
            <Stack width={"30%"}>
              <Chip label={translateRequirementType(requirement.objType)} color="secondary" />

            </Stack>

            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={1}
              width={"30%"}
              flexWrap={"wrap"}
            >
              {requirement?.idsDetails?.length > 0 ? (
                findClassesForGroups(
                  requirement.idsDetails,
                  requirement.objType
                ).map((detail) => <Chip label={detail.name} color="warning" />)
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
  BLOCKING_LESSON_AT_THE_SAME_TIME: (requirement) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          {translateRequirementType(requirement.type)}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Chip label={translateRequirementType(requirement.type)} color="success" />
            <Chip label={translateRequirementType(requirement.attType)} color="secondary" />
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement?.idsDetails?.length > 0 ? (
                requirement?.idsDetails?.map((detail) => (
                  <Chip label={detail.name} color="info" />
                ))
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement?.groupsDetails?.length > 0 ? (
                findClassesForGroups(requirement.groupsDetails, "GROUP")?.map((detail) => (
                  <Chip label={detail.name} color="warning" />
                ))
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
  FORBIDDEN_LESSON_ON_THE_SAME_DAY: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        {translateRequirementType(requirement.type)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Chip label={translateRequirementType(requirement.type)} color="success" />
          <Chip label={translateRequirementType(requirement.attType)} color="secondary" />
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.idsDetails?.length > 0 ? (
              requirement?.idsDetails?.map((detail) => (
                <Chip label={detail.name} color="info" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.groupsDetails?.length > 0 ? (
              findClassesForGroups(requirement.groupsDetails, "GROUP").map((detail) => (
                <Chip label={detail.name} color="warning" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
  EDGE_LESSON: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        {translateRequirementType(requirement.type)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Chip label={translateRequirementType(requirement.type)} color="success" />
          <Chip label={translateRequirementType(requirement.attType)} color="secondary" />
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.idsDetails?.length > 0 ? (
              requirement?.idsDetails?.map((detail) => (
                <Chip label={detail.name} color="info" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement.groupsDetails.length > 0 ? (
              findClassesForGroups(requirement.groupsDetails, "GROUP").map((detail) => (
                <Chip label={detail.name} color="warning" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
  LESSON_DISTRIBUTION: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        {translateRequirementType(requirement.type)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Chip label={translateRequirementType(requirement.type)} color="success" />

          <Chip label={translateRequirementType(requirement.distType)} color="info" />
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.groupsDetails?.length > 0 ? (
              findClassesForGroups(requirement.groupsDetails, "GROUP").map((detail) => (
                <Chip label={detail.name} color="warning" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
  LABELED_LESSONS_PER_DAY: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        {translateRequirementType(requirement.type)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Chip label={translateRequirementType(requirement.type)} color="success" />
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.groupsDetails?.length > 0 ? (
              findClassesForGroups(requirement.groupsDetails, "GROUP").map((detail) => (
                <Chip label={detail.name} color="info" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.labelDetails?.length > 0 ? (
              requirement?.labelDetails?.map((detail) => (
                <Chip label={detail.name} color="warning" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
  LESSON_START: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        {translateRequirementType(requirement.type)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}

        >
          <Chip label={translateRequirementType(requirement.type)} color="success" />
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.lessonsStarts?.length > 0 ? (
              requirement?.lessonsStarts?.map((detail) => (
                <Chip label={detail} color="info" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
            {requirement?.groupsDetails?.length > 0 ? (
              findClassesForGroups(requirement.groupsDetails, "GROUP").map((detail) => (
                <Chip label={detail.name} color="warning" />
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>Brak</p>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
};

export const renderCorrectRequirement = (requirement) => {
  return possibleRequirements[requirement.type](requirement);
};

const translateRequirementType = (type) => {
  switch (type) {
    case "PREFERENCE":
      return "Preferencja";
    case "BLOCKING_LESSON_AT_THE_SAME_TIME":
      return "Zablokowanie lekcji w tym samym czasie";
    case "FORBIDDEN_LESSON_ON_THE_SAME_DAY":
      return "Zablokowanie lekcji w tym samym dniu";
    case "EDGE_LESSON":
      return "Lekcja krawędziowa";
    case "LESSON_DISTRIBUTION":
      return "Rozkład lekcji";
    case "LABELED_LESSONS_PER_DAY":
      return "Oznaczone lekcje w ciągu dnia";
    case "LESSON_START":
      return "Początek lekcji";
    case "LESSON_TYPE":
      return "Przedmiot";
    case "LESSON_LABEL":
      return "Oznaczenie lekcji";
    case "EVEN":
      return "Parzyste";
    case "SPECIFIC":
      return "Specyficzne";
    case "RANGE":
      return "Zakres";
    case "TEACHER":
      return "Nauczyciel";
    case "GROUP":
      return "Grupa";
    case "CLASSROOM":
      return "Sala";
    case "UNIQUE_ID":
      return "Lekcja";
    default:
      return type;
  }
};
