import React from "react";
import styles from "./Navigation.module.scss";
import LoadingBar from "../LoadingBar/LoadingBar";
import { Button, Divider } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

const UserNav = ({ loading, isUserLogged, schoolName, navigate }) => {
  if (loading) return <LoadingBar />;
  return (
    <div className={styles.userNav}>
      <div className={styles.profile}>
        {loading ? (
          <LoadingBar />
        ) : !isUserLogged ? (
          <Button
            startIcon={<LoginIcon sx={{ width: "18px", margin: "0px" }} />}
            variant="outlined"
            color="primary"
            onClick={() => navigate("/login")}
          >
            Logowanie
          </Button>
        ) : (
          <div className={styles.loggedIn}>
            <p>ZALOGOWANY NA KONTO:</p>
            <Button onClick={() => navigate("/profile")}>{schoolName}</Button>
          </div>
        )}
      </div>
      <div className={styles.policy}>
        <a href="#privacy-policy">Polityka Prywatności</a>
        <Divider orientation="vertical" flexItem />
        <a href="#terms-of-service">Regulamin</a>
      </div>
    </div>
  );
};

export default UserNav;
