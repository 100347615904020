export const capabilities = [
  {
    title: "Podział klas dla grupy",
    description:
      "Twórz grupy w ramach danej klasy, jeśli niektóre zajęcia mogą odbywać się równocześnie.",
  },
  {
    title: "Rozkład lekcji",
    description:
      "Dopasuj plan lekcji do tygodniowego rozkładu, zapewniając dowolne rozłożenie zajęć i minimalizując nadmiarowe obciążenia.",
  },
  {
    title: "Preferencje",
    description:
      "Określ, kiedy i gdzie mają odbywać się lekcje, biorąc pod uwagę dostępność nauczycieli, sal oraz preferencje uczniów.",
  },
  {
    title: "Rozpoczęcie lekcji",
    description:
      "Ustal dokładne godziny rozpoczęcia lekcji dla wybranych grup i dni, aby dostosować harmonogram do różnych potrzeb i preferencji.",
  },
  {
    title: "Kontrola ilości trudnych lekcji w ciągu dnia",
    description:
      "Kontroluj liczbę lekcji o określonej etykiecie (np. lekcja trudna) w ciągu dnia, aby uniknąć nadmiernego obciążenia uczniów.",
  },
  {
    title: "Wybór lekcji skrajnych",
    description:
      "Zarządzaj pierwszymi i ostatnimi lekcjami w dniach szkolnych, aby lepiej dopasować harmonogram do codziennych rutyn.",
  },
  {
    title: "Blokowanie lekcji w tym samym dniu",
    description:
      "Ustal zasady, które uniemożliwią odbywanie się określonych lekcji w tym samym dniu, aby lepiej rozplanować obciążenie uczniów i nauczycieli.",
  },
  {
    title: "Blokowanie lekcji w tym samym czasie",
    description: "Zablokuj możliwość odbywania się lekcji w tym samym czasie.",
  },
  {
    title: "Minimalizacja okienek nauczycieli",
    description:
      "Zredukuj czas oczekiwania nauczycieli pomiędzy lekcjami, optymalizując ich harmonogram i zwiększając efektywność pracy.",
  },
  {
    title: "Likwidacja okienek w planach uczniów",
    description:
      "Likwiduj przerwy między lekcjami dla uczniów, tworząc bardziej spójne i efektywne plany zajęć.",
  },
];
