import React from "react";
import { Stack, Chip } from "@mui/material";

const GroupsRow = ({ classLesson, styles, handleRemoveGroup }) => {
  return (
    <Stack flexDirection="row" gap={1}>
      {classLesson?.groupDetails?.length === 0
        ? "Brak dodanych grup"
        : classLesson?.groupDetails?.map((group) => (
            <div className={styles.chipWrapper} key={`group-${group._id.$oid}`}>
              <Chip
                label={group?.name}
                onDelete={
                  classLesson?.groupDetails?.length === 1
                    ? undefined
                    : () => handleRemoveGroup(group._id.$oid)
                }
                classes={{ root: styles.chip }}
              />
            </div>
          ))}
    </Stack>
  );
};

export default GroupsRow;
