import { useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import styles from "./FeedbackForm.module.scss";
import DataService from "../../services/DataService";

export const FeedbackForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThanksOpen, setIsThanksOpen] = useState(false);
  const [feedback, setFeedback] = useState({
    like: "",
    dislike: "",
    subscribe: false,
  });
  const [error, setError] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openThanks = () => setIsThanksOpen(true);
  const closeThanks = () => setIsThanksOpen(false);

  const resetForm = () => {
    setFeedback({
      like: "",
      dislike: "",
      subscribe: false,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFeedback({
      ...feedback,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const data = {
        possitive: feedback.like,
        negative: feedback.dislike,
        newsletter: feedback.subscribe,
      };

      await DataService.postData(data, "feedback");
      closeModal();
      resetForm();

      openThanks();
    } catch (err) {
      setError("Failed to send feedback. Please try again.");
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Button variant="contained" color="primary" onClick={openModal}>
          Prześlij opinię!
        </Button>
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Box className={styles.modal}>
            <Typography variant="h5" component="h2" className={styles.title}>
              Pomóż nam rozwinąć aplikację! <br /> Podziel się swoją opinią!
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                label="Co Ci się podoba"
                name="like"
                multiline
                rows={4}
                value={feedback.like}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Co Ci się nie podoba"
                name="dislike"
                multiline
                rows={4}
                value={feedback.dislike}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <Typography variant="body2" fontSize={"14px"} fontWeight={400}>
                Jeśli chcesz mieć realny wpływ na rozwój naszej aplikacji,
                prosimy o wyrażenie zgody na kontakt. Zaznacz poniżej, abyśmy
                mogli się z Tobą skontaktować w celu oddania głosu na
                proponowane rozwiązania lub zadania pytań dotyczących dalszego
                rozwoju aplikacji.
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    name="subscribe"
                    checked={feedback.subscribe}
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontSize: "12px" }}>
                    Wyrażam zgodę na kontakt w celu oddania głosu na rozwiązania
                    lub pytania dotyczące dalszego rozwoju aplikacji.
                  </Typography>
                }
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Wyślij
              </Button>
            </form>
            <Button
              variant="text"
              color="secondary"
              className={styles.closeButton}
              onClick={closeModal}
            >
              Zamknij
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={isThanksOpen}
        onClose={closeThanks}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isThanksOpen}>
          <Box className={styles.thanks}>
            <Typography variant="h5" component="h2">
              Dziękujemy za przesłanie opinii
            </Typography>
            <Button variant="contained" color="primary" onClick={closeThanks}>
              Zamknij
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FeedbackForm;
