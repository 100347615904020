import { Chip, Stack, Typography } from "@mui/material";

export const getEvaluationRate = (rate) => {
  if(rate > 0 && rate < 5) {
    return <Chip label={rate} color="warning" />;
  } else if (rate >= 5) {
    return <Chip label={rate} color="error" />;
  } else {
    return <Chip label={rate} color="success" />;
  }
};

const translations = {
  TEACHER_COLLISIONS: "Kolizje nauczycieli",
  CLASSROOM_COLLISIONS: "Kolizje sal lekcyjnych",
  STUDENT_GAPS: "Okienka uczniów",
  LESSON_TYPE_EVENNESS: "Rownomierne rozłożenie przedmiotów",
  PREFERENCE: "Preferencje",
  LESSON_START: "Początek lekcji",
  LESSON_DISTRIBUTION: "Rozkład lekcji",
  LABELED_LESSONS_PER_DAY: "Ilość etykietowanych lekcji w ciągu dnia",
  FORBIDDEN_LESSON_ON_THE_SAME_DAY: "Zakazana lekcja w tym samym dniu",
  BLOCKING_LESSON_AT_THE_SAME_TIME: "Blokująca lekcja w tym samym czasie",
  EDGE_LESSON: "Skrajne lekcje",
};

export const getBestEvaluations = (evaluations) => {
  const generatedBadges = [];
  const redBadge = (label, level) => {
    const translatedLabel = translations[label];
    return (
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography>{translatedLabel}</Typography>
        <Chip label={level} color="error" />
      </Stack>
    );
  };
  
  const yellowBadge = (label, level) => {
    const translatedLabel = translations[label];
    return (
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography>{translatedLabel}</Typography>
        <Chip label={level} color="warning" />
      </Stack>
    );
  };

  // const greenBadge = (label, level) => {
  //   const translatedLabel = translations[label];
  //   return (
  //     <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
  //       <Typography>{translatedLabel}</Typography>
  //       <Chip label={level} color="success" />
  //     </Stack>
  //   );
  // };

  const badgeDisposition = (label, level) => {
    if(level > 0 && level < 5) {
      generatedBadges.push(yellowBadge(label, level));
    } else if (level >= 5) {
      generatedBadges.push(redBadge(label, level));
    }
  }

  Object.keys(evaluations).forEach((key, value) => {
    badgeDisposition(key, evaluations[key]);
  });

  return generatedBadges;
};

export const evaluationTime = (time) => {
  if (time < 230) {
    return <Chip label={`${time}s`} color="success" />;
  } else if (time >= 230 && time < 300) {
    return <Chip label={`${time}s`} color="warning" />;
  } else if (time >= 300) {
    return <Chip label={`${time}s`} color="error" />;
  }
};
