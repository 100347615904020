import { Container, Typography, Box } from "@mui/material";

const Regulations = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
      <ol>
        <li>
            <strong>Definicje</strong>
            <ul>
                <li><strong>Usługa:</strong> Generowanie planów lekcji za pośrednictwem aplikacji.</li>
                <li><strong>Użytkownik:</strong> Osoba fizyczna lub prawna korzystająca z Usługi.</li>
                <li><strong>Operator:</strong> Właściciel aplikacji, EasySchedule sp. z o.o., z siedzibą we Wrocławiu, ul. Śrutowa 4/5, 50-256 Wrocław, NIP: 8982310426, REGON: 529304377.</li>
            </ul>
        </li>
        <li>
            <strong>Postanowienia Ogólne</strong>
            <ul>
                <li>Usługa jest dostępna w dwóch wersjach: darmowej i płatnej.</li>
                <li>Użytkownik zobowiązuje się do korzystania z Usługi zgodnie z regulaminem i obowiązującym prawem.</li>
            </ul>
        </li>
        <li>
            <strong>Rejestracja i Konto Użytkownika</strong>
            <ul>
                <li>Rejestracja wymaga podania prawdziwych danych.</li>
                <li>Użytkownik zobowiązany jest do ochrony danych logowania.</li>
            </ul>
        </li>
        <li>
            <strong>Zakres Usługi</strong>
            <ul>
                <li><strong>Wersja darmowa:</strong>
                    <ul>
                        <li>Konfiguracja szkoły.</li>
                        <li>Dodawanie wymagań dotyczących planu lekcji z ograniczoną ilością personalizacji.</li>
                        <li>Generowanie planu lekcji.</li>
                        <li>Eksportowanie planu do PDF.</li>
                    </ul>
                </li>
                <li><strong>Wersja płatna:</strong>
                    <ul>
                        <li>Zaawansowane opcje konfiguracji szkoły.</li>
                        <li>Szczegółowe i zaawansowane wymagania dotyczące planu lekcji.</li>
                        <li>Generowanie planu lekcji z pełnymi opcjami personalizacji.</li>
                        <li>Eksportowanie planu do PDF.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
            <strong>Ograniczenia</strong>
            <ul>
                <li>Darmowa wersja aplikacji może być ograniczona pod względem liczby generowanych planów lekcji oraz dostępnych opcji konfiguracyjnych.</li>
                <li>Operator zastrzega sobie prawo do wprowadzenia limitów użytkowania dla użytkowników wersji darmowej, w celu zapewnienia stabilności i jakości usługi dla wszystkich użytkowników.</li>
                <li>Aplikacja może nie generować idealnych planów lekcyjnych w przypadku sprzecznych wymagań lub zbyt rygorystycznych ograniczeń. Zachęcamy do stopniowego zwiększania wymagań, aby uzyskać jak najlepsze wyniki.</li>
            </ul>
        </li>
        <li>
            <strong>Wersje i Aktualizacje</strong>
            <ul>
                <li>Operator może wprowadzać nowe wersje i aktualizacje aplikacji, które mogą zmieniać zakres dostępnych funkcji zarówno w wersji darmowej, jak i płatnej.</li>
                <li>Użytkownik zostanie powiadomiony o takich zmianach z odpowiednim wyprzedzeniem.</li>
            </ul>
        </li>
        <li>
            <strong>Płatności</strong>
            <ul>
                <li>Cennik dostępny na stronie Operatora.</li>
                <li>Opłaty nie podlegają zwrotowi po wygenerowaniu płatnych planów.</li>
                <li>Okres trwania subskrypcji wynosi 30 dni.</li>
            </ul>
        </li>
        <li>
            <strong>Odstąpienie od Umowy</strong>
            <ul>
                <li>Użytkownik ma prawo odstąpić od umowy w ciągu 14 dni od jej zawarcia bez podania przyczyny, zgodnie z ustawą z dnia 30 maja 2014 r. o prawach konsumenta, art. 27.</li>
                <li>Aby skorzystać z prawa odstąpienia, użytkownik musi poinformować Operatora o swojej decyzji w formie jednoznacznego oświadczenia (np. pocztą elektroniczną).</li>
                <li>Opłata nie podlega zwrotowi po wygenerowaniu planu lekcyjnego.</li>
            </ul>
        </li>
        <li>
            <strong>Polityka Prywatności</strong>
            <ul>
                <li>Dane osobowe przetwarzane są zgodnie z Polityką Prywatności dostępną na stronie Operatora.</li>
            </ul>
        </li>
        <li>
            <strong>Odpowiedzialność Operatora</strong>
            <ul>
                <li>Operator nie odpowiada za szkody wynikłe z korzystania z Usługi.</li>
                <li>Operator zastrzega sobie prawo do wprowadzania zmian w Usłudze.</li>
            </ul>
        </li>
        <li>
            <strong>Reklamacje</strong>
            <ul>
                <li>Użytkownik może zgłaszać reklamacje dotyczące funkcjonowania Usługi na adres e-mail: kontakt@easyschedule.pl.</li>
                <li>Reklamacje będą rozpatrywane w terminie 14 dni od daty ich otrzymania.</li>
            </ul>
        </li>
        <li>
            <strong>Rozwiązanie Umowy</strong>
            <ul>
                <li>Użytkownik może w każdej chwili zrezygnować z korzystania z Usługi.</li>
                <li>Operator może zawiesić konto w przypadku naruszenia regulaminu.</li>
            </ul>
        </li>
        <li>
            <strong>Postanowienia Końcowe</strong>
            <ul>
                <li>Regulamin wchodzi w życie z dniem publikacji na stronie Operatora.</li>
                <li>Wszelkie spory rozstrzygane będą przez sąd właściwy dla siedziby Operatora.</li>
            </ul>
        </li>
        <li>
            <strong>Kontakt</strong>
            <ul>
                <li>Wszelkie pytania i uwagi należy kierować na adres e-mail Operatora: kontakt@easyschedule.pl.</li>
            </ul>
        </li>
        <li>
            <strong>Dane Firmy</strong>
            <ul>
                <li>EasySchedule sp. z o.o., z siedzibą we Wrocławiu, ul. Śrutowa 4/5, 50-256 Wrocław, NIP: 8982310426, REGON: 529304377.</li>
            </ul>
        </li>
        <li>
            <strong>Czas Dostarczenia Usługi</strong>
            <ul>
                <li>Usługa zostaje udostępniona użytkownikowi natychmiast po zakończeniu procesu rejestracji i/lub dokonaniu płatności.</li>
            </ul>
        </li>
    </ol>
      </Box>
    </Container>
  );
};

export default Regulations;
