export const planGeneratorErrors = (errors) => {
  const errorsArray = [];
  errors.forEach((error) => {
    if (error?.LESSON_EMPTY_CLASSROOMS) {
      errorsArray.push({
        id: error.LESSON_EMPTY_CLASSROOMS,
        value: "Lekcja nie ma przypisanej sali",
      });
    }

    if (error?.LESSON_NO_TEACHER) {
      errorsArray.push({
        id: error.LESSON_NO_TEACHER,
        value: "Lekcja nie ma przypisanego nauczyciela",
      });
    }

    if (error?.LESSON_NO_LESSON_TYPE) {
      errorsArray.push({
        id: error.LESSON_NO_LESSON_TYPE,
        value: "Lekcja nie ma przypisanego przedmiotu",
      });
    }

    if (error?.LESSON_NO_LESSON_LABEL) {
      errorsArray.push({
        id: error.LESSON_NO_LESSON_LABEL,
        value: "Lekcja nie ma przypisanej etykiety",
      });
    }

    if (error?.LESSON_NO_CLASSROOMS) {
      errorsArray.push({
        id: error.LESSON_NO_CLASSROOMS,
        value: "Lekcja nie ma przypisanych sal",
      });
    }

    if (error?.LESSON_EMPTY_GROUPS) {
      errorsArray.push({
        id: error.LESSON_EMPTY_GROUPS,
        value: "Lekcja nie ma przypisanych grup",
      });
    }

    if (error?.LESSON_GROUP_NOT_EXIST) {
      errorsArray.push({
        id: error.LESSON_GROUP_NOT_EXIST,
        value: "Grupa nie istnieje",
      });
    }

    if (error?.LESSON_CLASSROOM_NOT_EXIST) {
      errorsArray.push({
        id: error.LESSON_CLASSROOM_NOT_EXIST,
        value: "Sala nie istnieje",
      });
    }

    if (error?.GROUP_NO_LESSONS) {
      errorsArray.push({
        id: error.GROUP_NO_LESSONS,
        value: "Grupa nie ma przypisanych lekcji",
      });
    }

    if (error?.GROUP_TO_MANY_LESSONS) {
      errorsArray.push({
        id: error.GROUP_TO_MANY_LESSONS.group,
        value: `Grupa ma za dużo przypisanych lekcji. Przypisano ${error.GROUP_TO_MANY_LESSONS.lessons_cnt}, możliwe ${error.GROUP_TO_MANY_LESSONS.possible_lessons_cnt}`,
      });
    }

    if (error?.EXCEEDED_NUM_OF_FREE_PLANS) {
      errorsArray.push({
        id: null,
        value: "Przekroczono limit darmowych planów",
      });
    }
  });

  return {
    errors: errorsArray,
    error: errorsArray.length > 0,
    status: 400,
  };
};
