export const authPasswordChecker = (password, repeatPassword) => {
  if (password.length < 8) {
    return "Hasło musi mieć co najmniej 8 znaków";
  }

  if (!/(?=.*[A-Z])/.test(password)) {
    return "Hasło musi zawierać przynajmniej jedną dużą literę";
  }

  if (!/(?=.*\d)/.test(password)) {
    return "Hasło musi zawierać przynajmniej jedną cyfrę";
  }

  if (!/(?=.*[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\/?])/.test(password)) {
    return "Hasło musi zawierać przynajmniej jeden znak specjalny";
  }

  if (password !== repeatPassword) {
    return "Hasła muszą być takie same";
  }

  return true;
};
