import React from "react";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  Button,
} from "@mui/material";
import { mainNavItems } from "./nav.config";
import Logo from "../../icons/es-logo.png";
import LoadingBar from "../LoadingBar/LoadingBar";
import LoginIcon from "@mui/icons-material/Login";

const MobileNav = ({
  handleDrawer,
  isOpenDrawer,
  loading,
  isUserLogged,
  schoolName,
  navigate,
}) => {
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawer(false)}>
      <List>
        <img src={Logo} alt="brand-logo" style={{ width: "100%" }}></img>
        <Divider />
        {mainNavItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton>{item.render}</ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <a href="#privacy-policy">Polityka Prywatności</a>
        </ListItem>
        <ListItem>
          <a href="#terms-of-service">Regulamin</a>
        </ListItem>
        <Divider />
        <ListItem>
          {loading ? (
            <LoadingBar />
          ) : !isUserLogged ? (
            <Button
              startIcon={<LoginIcon sx={{ width: "18px", margin: "0px" }} />}
              variant="outlined"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Logowanie
            </Button>
          ) : (
            <div>
              <p style={{textAlign: 'center'}}>Zalogowany jako</p>
              <Button onClick={() => navigate("/profile")}>{schoolName}</Button>
            </div>
          )}
        </ListItem>
        <Divider />
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpenDrawer} onClose={handleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default MobileNav;
