import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Polityka Prywatności
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Administrator Danych
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Administratorem danych osobowych jest EasySchedule sp. z o.o., z
          siedzibą we Wrocławiu, ul. Śrutowa 4/5, 50-256 Wrocław, NIP:
          8982310426, REGON: 529304377.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Dane Osobowe
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Dane zbierane: imię, nazwisko, adres e-mail, nazwa szkoły, dane
          logowania. Dane są zbierane w celu świadczenia usług, kontaktu z
          użytkownikiem, obsługi płatności i wsparcia technicznego.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Podstawa Przetwarzania Danych
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Dane przetwarzane są na podstawie zgody użytkownika, niezbędności do
          wykonania umowy, oraz zgodnie z prawem (RODO).
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Udostępnianie Danych
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Dane osobowe mogą być udostępniane podmiotom przetwarzającym dane na
          zlecenie administratora, np. dostawcom usług IT, firmom płatniczym, w
          zakresie niezbędnym do świadczenia usług.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Prawa Użytkownika
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Użytkownik ma prawo do dostępu do swoich danych, ich sprostowania,
          usunięcia, ograniczenia przetwarzania, przenoszenia danych, sprzeciwu
          oraz cofnięcia zgody w dowolnym momencie. W celu realizacji swoich
          praw, użytkownik może skontaktować się z administratorem danych
          poprzez e-mail: kontakt@easyschedule.pl.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Przechowywanie Danych
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Dane osobowe będą przechowywane przez okres niezbędny do realizacji
          celów przetwarzania, a także zgodnie z obowiązującymi przepisami
          prawa.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Bezpieczeństwo Danych
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Administrator stosuje odpowiednie środki techniczne i organizacyjne,
          aby zapewnić ochronę danych osobowych przed nieautoryzowanym dostępem,
          zmianą, ujawnieniem lub zniszczeniem.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Pliki Cookies
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Strona internetowa wykorzystuje pliki cookies w celu poprawy
          funkcjonalności, analizowania ruchu oraz dostosowywania treści do
          użytkownika. Użytkownik może zarządzać ustawieniami cookies w
          przeglądarce internetowej.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Zmiany w Polityce Prywatności
        </Typography>
        <Typography paragraph sx={{ ml: 2 }}>
          ● Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce
          Prywatności. Zmiany będą publikowane na stronie internetowej.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Kontakt
        </Typography>
        <Typography paragraph sx={{ ml: 2, mb: 5 }}>
          ● W przypadku pytań lub uwag dotyczących Polityki Prywatności, prosimy
          o kontakt pod adresem e-mail: kontakt@easyschedule.pl.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
