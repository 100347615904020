import HomeIcon from "@mui/icons-material/Home";
import TableChartIcon from "@mui/icons-material/TableChart";
import Looks3Icon from "@mui/icons-material/Looks3";
import SchoolIcon from "@mui/icons-material/School";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import CalculateIcon from "@mui/icons-material/Calculate";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CreateIcon from "@mui/icons-material/Create";
import LabelIcon from "@mui/icons-material/Label";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SchoolInfoStore from "../../mobx/SchoolInfoStore";

import { Skeleton } from "@mui/material";
import { toJS } from "mobx";

export const planSubscription = (plan) => {
  if (plan === "FREE") {
    return "Wersja darmowa";
  } else if (plan === "BASIC") {
    return "Wersja PREMIUM";
  }

  return "";
};

export const schoolPlannerDrawer = [
  {
    id: 1,
    value: "Start",
    href: "/profile",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <HomeIcon />
      );
    },
  },
  {
    id: 2,
    value: "Lekcje",
    href: "lessons",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <ArticleIcon />
      );
    },
  },
  {
    id: 3,
    value: "Plany lekcji",
    href: "timetables",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <TableChartIcon />
      );
    },
  },
  {
    id: 4,
    value: "Klasy",
    href: "classes",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <Looks3Icon />
      );
    },
  },
  {
    id: 5,
    value: "Przedmioty",
    href: "subjects",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <CalculateIcon />
      );
    },
  },
  {
    id: 6,
    value: "Nauczyciele",
    href: "teachers",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <SchoolIcon />
      );
    },
  },
  {
    id: 7,
    value: "Sale lekcyjne",
    href: "classrooms",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <MeetingRoomIcon />
      );
    },
  },
  {
    id: 8,
    value: "Etykiety",
    href: "labels",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <LabelIcon />
      );
    },
  },
  {
    id: 9,
    value: "Ogólne",
    href: "general",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <AccountCircleIcon />
      );
    },
  },
];

export const advancedDrawer = [
  {
    id: 101,
    value: "Wymagania planu",
    href: "requirements",
    icon: function (isLoading) {
      return isLoading ? (
        <Skeleton variant="rectangular" width={260} height={35} />
      ) : (
        <SettingsIcon />
      );
    },
  },
  // {
  //   id: 102,
  //   value: "Zaawansowane",
  //   href: "advanced",
  //   icon: function (isLoading) {
  //     return isLoading ? (
  //       <Skeleton variant="rectangular" width={260} height={35} />
  //     ) : (
  //       <SettingsSuggestIcon />
  //     );
  //   },
  // },
  // {
  //   id: 103,
  //   value: "Kreator",
  //   href: "creator",
  //   icon: function (isLoading) {
  //     return isLoading ? (
  //       <Skeleton variant="rectangular" width={260} height={35} />
  //     ) : (
  //       <CreateIcon />
  //     );
  //   },
  // },
];

export const restDrawer = [
  // {
  //   id: 200,
  //   value: "Finanse",
  //   href: "finances",
  //   icon: function (isLoading) {
  //     return isLoading ? (
  //       <Skeleton variant="rectangular" width={260} height={35} />
  //     ) : (
  //       <MonetizationOnIcon />
  //     );
  //   },
  // },
  {
    id: 201,
    value: "Wyloguj",
    href: null,
    icon: <LogoutIcon color="warning" />,
  },
];

export const handleGeneratePlanErrors = (errors) => {
  const errorMessages = [];

  errors.errors.forEach((error, index) => {
    const foundLesson = SchoolInfoStore.findLesson(error.id.$oid);
    if (foundLesson) {
      const foundClass = SchoolInfoStore.findClass(
        foundLesson.student_class_id.$oid
      );
      errorMessages.push(
        <p>
          Lekcja: {foundLesson.lessonType.name}. Dla klasy: {foundClass.name}.{" "}
          {errors.errors[index].value}
        </p>
      );
    } else {
      const foundGroup = SchoolInfoStore.findGroup(error.id.$oid);
      if (foundGroup) {
        errorMessages.push(
          <p>
            Grupa: {foundGroup.name}. {errors.errors[index].value}
          </p>
        );
      }
    }
  });

  return errorMessages;
};
