import React, { useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import SchoolInfoStore from "../../../mobx/SchoolInfoStore";
import {
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  Button,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import SingleClassLessons from "./SingleClassLessons/SingleClassLessons";
import ModalComponent from "../../Modal/ModalComponent";
import CopyLessonsStore from "../../../mobx/CopyLessonsStore";

const Lessons = observer(() => {
  const { classes, lessons } = toJS(SchoolInfoStore);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedLessonsToCopy, setSelectedLessonsToCopy] = useState([]);
  const [classLessons, setClassLessons] = useState([]);
  const [copyToClass, setCopyToClass] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClassChange = (event) => {
    setCopyToClass(event.target.value);
    console.log(event.target.value);
  };

  const handleCopyButton = (event, selectedClass) => {
    event.stopPropagation();
    setSelectedClass(selectedClass);
    const lessonsToCopy = SchoolInfoStore.findLessonsByClassId(selectedClass._id.$oid);
    setClassLessons(lessonsToCopy);
    setSelectedLessonsToCopy(lessonsToCopy)
  };

  const handleCheckboxChange = (event) => {
    const lessonId = event.target.value;
    const lesson = lessons.data.find((lesson) => lesson._id.$oid === lessonId);

    setSelectedLessonsToCopy((prevState) => {
      const ids = prevState.map((lesson) => lesson._id.$oid);

      return ids.includes(lessonId)
        ? prevState.filter((lesson) => lesson._id.$oid !== lessonId)
        : [...prevState, lesson];
    });
  };

  const handleClose = () => {
    setSelectedClass(undefined);
    setSelectedLessonsToCopy([]);
    setClassLessons([]);
  };

  const handleCopyLessons = async () => {
    if (!selectedClass) return;
    setLoading(true);
    await CopyLessonsStore.copyLessonsFromClass(selectedClass, copyToClass, selectedLessonsToCopy);
    setLoading(false);
    handleClose();
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ width: "100%" }}
    >
      <Typography variant="h2" sx={{ width: "100%", mb: 3 }}>
        Lekcje
      </Typography>
      <Stack gap={2} sx={{ width: "100%" }}>
        {classes?.data
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((schoolClass, index) => (
            <Accordion
              key={schoolClass._id.$oid}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography>Klasa {schoolClass.name}</Typography>
                <Tooltip
                  placement="top"
                  title={
                    selectedClass?._id.$oid === schoolClass._id.$oid
                      ? "Kopiowanie aktywne"
                      : "Kopiuj lekcje"
                  }
                >
                  <IconButton
                    onClick={(event) => handleCopyButton(event, schoolClass)}
                    size="small"
                    color={
                      selectedClass?._id.$oid === schoolClass._id.$oid
                        ? "primary"
                        : "default"
                    }
                    sx={{ ml: "auto" }}
                  >
                    {selectedClass?._id.$oid === schoolClass._id.$oid ? (
                      <CheckIcon />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2} sx={{ width: "100%" }}>
                  <SingleClassLessons singleClass={schoolClass} />
                </Stack>
              </AccordionDetails>
              <AccordionActions>
                {/* <Button size="small">Edytuj</Button>
                <Button size="small">Usuń</Button> */}
              </AccordionActions>
            </Accordion>
          ))}
      </Stack>
      <ModalComponent
        isOpen={Boolean(selectedClass)}
        handleClose={handleClose}
        width="500px"
      >
        <Typography variant="h2" fontSize={"1.75rem"} marginBottom={4}>
          Kopiowanie z klasy {selectedClass?.name}
        </Typography>

        <Typography variant="p">
          Wybierz klasę do której chcesz skopiować lekcje
        </Typography>
        <FormControl fullWidth size="small" sx={{ mt: 2 }}>
          <InputLabel id="class">Klasa</InputLabel>
          <Select
            labelId="class"
            id="class"
            defaultValue=""
            label="Klasa"
            onChange={handleClassChange}
            MenuProps={{
              PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
            }}
            size="small"
            sx={{ mb: 2 }}
          >
            {classes.data
              .filter(
                (classData) => classData._id.$oid !== selectedClass?._id.$oid
              )
              .map((classData) => (
                <MenuItem key={classData._id.$oid} value={classData._id.$oid}>
                  {classData.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {classLessons.length > 0 ? (
          <FormGroup
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              maxHeight: "50vh",
            }}
          >
            {classLessons.sort((a,b) => a.lessonType.name.localeCompare(b.lessonType.name)).map((lesson) => (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={selectedLessonsToCopy
                      .map((lesson) => lesson._id.$oid)
                      .includes(lesson._id.$oid)}
                    onChange={handleCheckboxChange}
                    value={lesson._id.$oid}
                  />
                }
                label={lesson.lessonType.name}
                key={lesson._id.$oid}
              />
            ))}
          </FormGroup>
        ) : null}
        <Stack
          gap={2}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          marginTop={2}
        >
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            size="small"
          >
            Zamknij
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleCopyLessons}
            disabled={copyToClass === "" || loading}
          >
            {loading ? "Kopiowanie..." : "Kopiuj lekcje"}
          </Button>
        </Stack>
      </ModalComponent>
    </Stack>
  );
});

export default Lessons;
