import React, { useState } from "react";
import {
  Stack,
  Button,
} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import styles from './Requirement.module.scss';
import { renderCorrectRequirement } from "./requirementConfig";
import RequirementsStore from "../../../../mobx/RequirementsStore";
import DialogAlert from "../../../Alert/DialogAlert";

const Requirement = ({ requirement }) => {
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const handleDialogOpen = () => setIsRemoveDialogOpen(true);
  const handleDialogClose = () => setIsRemoveDialogOpen(false);
  
  const deleteHandler = async (id) => {
    const response = await RequirementsStore.deleteRequirement(id);
  };

  return (
    <Stack gap={2} display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'} className={styles.requirement}>
     {renderCorrectRequirement(requirement)}
      <Stack gap={1} display={'flex'} flexDirection={'row'}>
        {/* TODO */}
        {/* <Button color="secondary">
          <EditIcon/>
        </Button> */}
        <Button color="error" onClick={handleDialogOpen}>
          <DeleteForeverIcon/>
        </Button>
      </Stack>
      <DialogAlert
          isDialogOpen={isRemoveDialogOpen}
          handleDialogClose={handleDialogClose}
          cb={() => deleteHandler(requirement._id)}
          content={"Czy na pewno chcesz usunąć wymaganie?"}
          title={"Wymagania"}
        />
    </Stack>
  );
};

export default Requirement;
